import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { UserContext } from "../context/UserContext";

const RequireAuth = () => {
    const {language} = useContext(UserContext);
    return (
        <Container>
            <h1>
                {language =='fr'? 'non authorisé' :'page not authorized'}
            </h1>
            <span>
                {language =='fr'? "La page que vous recherchez nécessite que vous soyez connecté a un compte"
                    :
                "The page you are looking for require you to be logged into an account"}
            </span>
            <span>
            {language =='fr'?"Vous pouvez  ":'You can '}
                <Link to="/register" style={{marginTop:10}} >
                    {language =='fr'?"créer un compte":'create an account'}
                </Link>
                {language =='fr'?" ou ":' or '}
                <Link to={{pathname:"/login", state:{loginPersist:true}}} style={{marginTop:10}} >
                    {language =='fr'?"vous connecter a votre compte":'log into your account'}
                </Link>
                {language =='fr'?" si vous en avez déjà un.":' if you already have one.'}
            </span>
        </Container>
    )
}

export default RequireAuth;

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction:column;
    height:70vh;
    align-items: center;
    justify-content: center;
    background-color:rgba(28,125,45, 0.5);
    margin-top:20px;
    margin-bottom:50px;
    border-radius:10px;
    span {
        text-align:center;
        font-size:20px;
        margin:10px;
    }
`