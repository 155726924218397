import React, { useContext } from "react";
import styled from "styled-components";
import 'bootstrap/dist/css/bootstrap.min.css';
import { UserContext } from "../context/UserContext";


const DropDown = () => {
  const {language, setLanguage} = useContext(UserContext);
  function changeLang (lang) {
    localStorage.setItem('storedLanguage', lang);
    setLanguage(lang);
  }

  return (
      <div className="dropdown">
          <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown">{language==='fr'? 'FR':'EN'} </a>
          <div className="dropdown-menu">
            <span onClick={() => changeLang('fr')} href="#" className="dropdown-item">Français</span>            
            <span onClick={() => changeLang('en')} href="#" className="dropdown-item">English</span> 
          </div>
      </div>
  );
}


export default function Footer() {
  const {language} = useContext(UserContext);
  return (
    <FooterContainer>
      <span>Copyright &copy; 2022 FLAG. {language==='fr'?'Tous droits réservés':'All rights reserved'}</span>
      <ul className="links">
        <li>
          <a>Omnisports Yaounde</a>
        </li>
        <li>
          <a>+(237) 222 21 00 36</a>
        </li>
        <li>
          <a>secretariat.paoi@gmail.com</a>
        </li>
        <li>
          <DropDown/>
        </li>
      </ul>
      {/* <ul className="social__links">
        <li>
          <BsFacebook />
        </li>
        <li>
          <AiFillInstagram />
        </li>
        <li>
          <BsLinkedin />
        </li>
      </ul> */}
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-evenly;
  background-color: #0169b2;
  border-radius: 0.5rem;
  padding: 2.5rem;
  color:white;
  ul {
    display: flex;
    list-style-type: none;
    gap: 2rem;
    li {
      text-decoration: none;
      color: white;
      transition: 0.3s ease-in-out;
      padding-left:5px;
      padding-right:5px;
      border-radius:5px;
      .dropdown {
        a {
          color:white;
          &:hover {
            color:#1C7D2D;
          }
        }
      }
      &:hover {
        background-color:rgba(255, 255, 255, 0.8);
        color: #1C7D2D;
        cursor: pointer;
      }
      svg {
        font-size: 1.3rem;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
    }
  }
  .dropdown-item {
    cursor: pointer;
  }
  @media screen and (min-width: 280px) and (max-width: 1024px) {
    flex-direction: column;
    gap: 2rem;
    ul {
      flex-direction: column;
    }
    .social__links {
      flex-direction: row;
    }
  }
`;
