import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import {collection, addDoc, query, getDocs} from 'firebase/firestore';
import { db, SCOPES } from '../App';
import { useHistory } from 'react-router';
import { useGoogleLogin } from '@react-oauth/google';
import { AuthContext } from '../context/AuthContext';
import { UserContext } from '../context/UserContext';


const CustomInput = ({type, placeholder, size, value, isDisabled, onChange, label}) => {
    const [focused, setFocused] = useState(false);
    const {language} = useContext(UserContext);
    return(
        <div style={{marginBottom:25, display:isDisabled ? 'none' : undefined}}>
            <div className={"col-md-1"}></div>
            <div className={"col-md-" + size}>
                {type === 'file'? 
                <label className="btn btn-primary" style={{borderRadius:10}}>
                    {language==='fr'? 'Choisir un fichier':'Choose a file'}
                <input
                    accept=".pdf,.doc,.docx,.txt,.ppt,.pptx,.xls,.xlsx"
                    hidden
                    disabled={isDisabled}
                    onChange={onChange}
                    placeholder={placeholder}
                    style={{borderRadius:10, height:38, borderColor:focused ? 'blue' : '#cccccc', borderWidth: focused ? 2:1, color:isDisabled? '#677' :'black'}}
                    type={type ? type : "text"}
                    
                    className="form-control shadow-none"
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    value={value}
                    />
                    </label>
                    :
                    <label style={{display:'flex', flex:1, flexDirection:'column'}}>
                        {label ? 
                        <span style={{marginTop:-13, marginBottom:3, marginLeft:6,fontSize:16.5}}>
                            {label}
                        </span>
                        : null
                        }
                        <input
                        disabled={isDisabled}
                        onChange={onChange}
                        placeholder={placeholder}
                        style={{borderRadius:10, height:38, borderColor:focused ? 'rgb(28,125,45)' : '#cccccc', borderWidth: focused ? 2:1, color:isDisabled? '#677' :'black'}}
                        type={type ? type : "text"}
                        id="form12"
                        className="form-control shadow-none"
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        value={value}
                        />
                    </label>
                    }
            </div>
            <div className={"col-md-" + 11-size}></div>
            
        </div>
    )
}

function customTheme (theme) {
    return {
        ...theme,
        borderRadius:10,
        borderWidth:5,
        colors:{
            ...theme.colors,
            primary25: 'rgba(28, 125, 45, 1)',
            primary:'green'
        }
    }
}

const CustomSelect = ({placeholder, size, options, isDisabled, value, setState, noOptionsMessage}) => {
    return(
    <div style={{marginBottom:25, display: isDisabled ? 'none' : undefined}}>
        <div className={"col-md-1"}></div>
        <div className={"col-md-" + size}>
            <Select
                isDisabled={isDisabled}
                noOptionsMessage={noOptionsMessage}
                options={ options }
                placeholder={placeholder}
                theme={customTheme}
                onChange={setState}
                value={value}
                />
        </div>
        <div className={"col-md-"+ 11-size}></div>
        
    </div>)
}


const CreateDoc = () => {
    const {user, gToken, setGToken} = useContext(AuthContext);
    const {language} = useContext(UserContext);

    let docObj = {};
    const [dBDocs, setDBDocs] = useState(null);
    const [organisation, setOrganisation] = useState('');
    const [period, setPeriod] = useState('');
    const [category, setCategory] = useState('');
    const [domain, setDomain] = useState('');
    const [OIType, setOIType] = useState('');
    const [reportType, setReportType] = useState('');
    const [country, setCountry] = useState('');
    const [docTitle, setDocTitle] = useState('');
    const [concernedTitle, setConcernedTitle] = useState('');
    const [docEditor, setDocEditor] = useState('');
    const [journal, setJournal] = useState('');
    const [fromValidityPeriod, setFromValidityPeriod] = useState('');
    const [toValidityPeriod, setToValidityPeriod] = useState('');
    const [publicationDate, setPublicationDate] = useState('');
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    function isInputAllowed (type) {
        return category && dBDocs[language].placeholders[type].allowed.includes(category.value);
    }

    function isSelectAllowed (type) {
        return category && dBDocs[language][type].allowed.includes(category.value);
    }
      
    async function getFormParams () {
        let uploadParams = {};
        const q = query(collection(db, "uploadparams"));

        const params = await getDocs(q);
        params.forEach(lang => {
            uploadParams[lang.id] = lang.data()
        });
        
        if(Object.keys(uploadParams).length ===0) {
            uploadParams = null;
        }

        setDBDocs(uploadParams);
    }

    const googleLogin = useGoogleLogin({
        scope:SCOPES,
        flow:'implicit',
        onSuccess: tokenRes => {
            console.log('Token response: ', tokenRes);
            // setGToken(tokenRes.access_token);
            setupDoc(tokenRes.access_token);
        },
        onError:() => {
            if(loading) {
                setLoading(false);
            }
        }
    })

    const setupDoc = async (accessToken) => {
        const currentDate = new Date();
        const fileName = docObj.title.split(' ').join('_')+ '_' + currentDate.toISOString()
        const GDocObj = await createDocs(fileName, accessToken)
        console.log("Google document object: ", GDocObj)
        if (GDocObj.error) {
            if (GDocObj.error.code ===401) {
                googleLogin()
            } else {
                alert (language==='fr'?"Une erreur est survenue lors de la creation du document.":'An error occured when creating the document.');
                if(loading) {
                    setLoading(false);
                }
            }
        } else {
            const result = await addDoc(collection(db, 'resources','documents', 'editing'), {...docObj, category:category.value,fileName:fileName+'.pdf', createdAt: new Date(), GDocsId:GDocObj.documentId, createdBy:user.id, creatorEmail:user.email});            
            console.log("Firestore query result: ", result);
            history.push({pathname:'/admin'});
            
            window.open("https://docs.google.com/document/d/"+ GDocObj.documentId+"/edit", "_blank");
        }
    };

    const createDocs = async (docName,authToken) => {
        //This function takes as argument the name of the document to be created
        //and the authorization token 
        const response = await fetch('https://docs.googleapis.com/v1/documents?title='+docName, {
            method:'POST',
            headers: new Headers({'Authorization':'Bearer '+authToken})
        })
        const fResponse = await response.json()
        // console.log('Formatted Response: ', fResponse);

        return fResponse;
    }
    
    const saveDocument = async(category, properties, GDocsId) => {
        const currentDate = new Date();
        const fileName = properties.title.split(' ').join('_')+ '_' + currentDate.toISOString() + '.' + 'json'
        const createdDoc =await addDoc(collection(db, 'resources','documents', 'editing'), {...properties, docName:fileName, category, createdAt: new Date()});
        // history.push({
        //     pathname:'./editor',
        //     state:{docId:createdDoc.id}
        //   });
    }
        
    const onFormSubmit = () => {
            const condition = 
            docTitle
            &&country 
            &&organisation
            && period
            && category
            && (!isSelectAllowed('domain') || domain)
            && (!isSelectAllowed('OIType') || OIType)
            && (!isSelectAllowed('reportType') || reportType)
            && (!isInputAllowed('concernedTitles') || concernedTitle)
            && (!isInputAllowed('editor') || docEditor)
            && (!isInputAllowed('journal') || journal)
            && (!isInputAllowed('validityPeriod') || (fromValidityPeriod && toValidityPeriod))
            && (!isInputAllowed('publicationDate') || publicationDate)           

        if (condition) {
            docObj={};
            docObj.title = docTitle;
            docObj.country = country.value;
            docObj.organisation = organisation.value;
            docObj.period = period.value;
            docObj.type = 'pdf';
            docObj.loaderEmail = user.email;
            if (isSelectAllowed('domain')) docObj.domain = domain.value;
            if (isSelectAllowed('OIType')) docObj.OIType = OIType.value;
            if (isSelectAllowed('reportType')) docObj.reportType = reportType.value;
            if (isInputAllowed('concernedTitles')) docObj.concernedTitles = concernedTitle;
            if (isInputAllowed('editor')) docObj.editor = docEditor;
            if (isInputAllowed('journal')) docObj.journal = journal;
            if (isInputAllowed('validityPeriod')) docObj.fromValidityPeriod = fromValidityPeriod;
            if (isInputAllowed('validityPeriod')) docObj.toValidityPeriod = toValidityPeriod;
            if (isInputAllowed('publicationDate')) docObj.publicationDate = publicationDate;

        
            console.log('Category: ', category);
            console.log('Document properties: ', docObj);
            
            if (gToken) {
                setupDoc(gToken);
            } else {
                googleLogin() 
            }
            
        } else {
            alert(language==='fr'?'Un ou plusieurs champs sont vides, vérifiez encore.':'One or more fields are empty, please check again');
        }
    }


    let periodOptions = [];
    if(dBDocs) {
        const initPer = dBDocs.en.period.initialPeriod;
        const finalPer = dBDocs.en.period.finalPeriod;
        for (let i =initPer; i<= finalPer; i++){
            periodOptions.push({label: i.toString(), value:i})
        }
    }

    const credentials = {
        "client_id":"69475241331-qog3ditpp443kb8s4q04ekaoks94fmui.apps.googleusercontent.com",
        "client_secret":"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",
        "redirect_uris":["http://localhost:3000","http://localhost"]        
    }

    useEffect(() => {
        getFormParams();
    }, []);
    useEffect(() => {
        setOrganisation('');
    }, [country])

    useEffect(() => {
        setDomain('');
        setOIType('');
        setReportType('');
    }, [category]);
    return (
        <div>
            <h1 style={{marginBottom:40, marginTop:20}} >{language==='fr'? 'Créer un document en ligne':'Create a document online'}</h1>
            <div className="row">
                <CustomInput
                    onChange={(e) => setDocTitle(e.target.value)}
                    value={docTitle}
                    placeholder={dBDocs? dBDocs[language].placeholders.title.value:''}
                    size={4}
                />
               <CustomSelect
                    type="country"
                    placeholder={language==='fr'? 'Pays':'Country'}
                    size={4}
                    options={dBDocs? dBDocs[language].country:[]}
                    value={country}
                    setState={(value) => {
                        setCountry(value);
                        setOrganisation('');
                    }}
                />
                {country ? (
                    <CustomSelect
                        type="organisation"
                        placeholder="Organisation"
                        size={5}
                        options={dBDocs? dBDocs[language].organisation[country.value]:[]}
                        value={organisation}
                        setState={(value) => setOrganisation(value)}
                    />
                ) : (
                    <CustomSelect
                        type="organisation"
                        placeholder="Organisation"
                        size={5}
                        noOptionsMessage={() =>language==='fr'? 'Veuillez selectionner un pays':'Choose a country'}
                        // options={dBDocs[language].organisation.CMR}
                    />
                )
                    
                }

                <CustomSelect
                    setState={(value) => setPeriod(value)}
                    value={period}
                    placeholder={language==='fr'? 'Période':'Period'}
                    size={4}
                    options={periodOptions}
                />
                <CustomSelect
                    type="category"
                    placeholder={language==='fr'? 'Catégorie':'Category'}
                    size={5}
                    options={dBDocs? dBDocs[language].category:[]}
                    value={category}
                    setState={(value) => setCategory(value)}
                />
                <CustomSelect
                    type="domain"
                    placeholder={language==='fr'? "Domaine d'intervention":'Intervention domain'}
                    size={5}
                    isDisabled={!isSelectAllowed('domain')}
                    options={dBDocs? dBDocs[language].domain.values:[]}
                    value={domain}
                    setState={(value) => setDomain(value)}
                />
                <CustomSelect
                    type="OIType"
                    placeholder={language==='fr'? "Type d'OI":'OI type'}
                    size={5}
                    isDisabled={!isSelectAllowed('OIType')}
                    options={dBDocs? dBDocs[language].OIType.values:[]}
                    value={OIType}
                    setState={(value) => setOIType(value)}
                />
                <CustomSelect
                    type="reportType"
                    placeholder={language==='fr'? 'Type de rapport':'Report type'}
                    size={5}
                    isDisabled={!(category && dBDocs[language].reportType.allowed.includes(category.value))}
                    options={dBDocs? dBDocs[language].reportType.values[category.value]:[]}
                    value={reportType}
                    setState={(value) => setReportType(value)}
                />
                <CustomInput
                    onChange={(e) => setConcernedTitle(e.target.value)}
                    value={concernedTitle}
                    placeholder={dBDocs? dBDocs[language].placeholders.concernedTitles.value:''}
                    size={4}
                    isDisabled={!isInputAllowed('concernedTitles')}
                />
                <CustomInput
                    onChange={(e) => setDocEditor(e.target.value)}
                    value={docEditor}
                    placeholder={dBDocs? dBDocs[language].placeholders.editor.value:''}
                    size={3}
                    isDisabled={!isInputAllowed('editor')}
                />
                <CustomInput
                    onChange={(e) => setJournal(e.target.value)}
                    value={journal}
                    placeholder={dBDocs? dBDocs[language].placeholders.journal.value:''}
                    size={4}
                    isDisabled={!isInputAllowed('journal')}
                />
                <CustomInput
                    type="date"
                    onChange={(e) => setFromValidityPeriod(e.target.value)}
                    value={fromValidityPeriod}
                    label={language==='fr'? 'Valide du':'Valid from'}
                    size={4}
                    isDisabled={!isInputAllowed('validityPeriod')}
                />
                <CustomInput
                    type="date"
                    onChange={(e) => setToValidityPeriod(e.target.value)}
                    value={toValidityPeriod}
                    label={'au'}
                    size={4}
                    isDisabled={!isInputAllowed('validityPeriod')}
                />
                <CustomInput
                    type='date'
                    onChange={(e) => setPublicationDate(e.target.value)}
                    value={publicationDate}
                    placeholder={dBDocs? dBDocs[language].placeholders.publicationDate.value:''}
                    label={language=='fr'?'Date de publication':'Publication date'}
                    size={4}
                    isDisabled={!isInputAllowed('publicationDate')}
                />
                <button disabled={loading} className="btn btn-secondary" style={{maxWidth:200, marginLeft:15, marginBottom:30, backgroundColor:'#1C7D2D'}} onClick={onFormSubmit} >
                {language==='fr'? 'Créer le document':'Create the document'}
                </button>
                {/* <div className="btn btn-primary" onClick={createDocs}>
                    Go to docs
                </div> */}
                {/* <div style={{marginBottom:25}}>
                    <div className={"col-md-1"}></div>
                    <div className={"col-md-4"}>
                        <button onClick={onFormSubmit} type="button" className="btn btn-success shadow-none" style={{width:140, borderRadius:10, marginTop:15}}>Créer</button>
                    </div>
                    <div className={"col-md-7"}></div>
                    
                </div> */}
            </div>
        </div>
    );
} 

export default CreateDoc;