import React, {createContext, useEffect, useState} from "react";
import { doc, getDoc, query } from "@firebase/firestore";
import { db } from "../App";
import { auth } from "../App";
export const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('storedUser')) || null);
    const [gToken, setGToken] = useState(null);

    const getUserMore = async(userInfos) => {
        const q = query(doc(db, "users", userInfos.id));
        const document = await getDoc(q);
        const adminQuery = query(doc(db, "admins", userInfos.id));
        const adminProps = await getDoc(adminQuery);
        let adminObj = adminProps.data();
        if (!adminObj) {
            adminObj ={admin:false, superadmin:false}
        }
        localStorage.setItem('storedUser', JSON.stringify({...userInfos, ...document.data(), id:document.id, ...adminObj}));
        setUser({...userInfos, ...document.data(), id:document.id, ...adminObj});
    }
    console.log('Current user: ', user);


    useEffect(() => {
        if(user && !user.phone) {
            getUserMore()
        }
    }, [user])

    useEffect(() => {
        auth.onAuthStateChanged(userSnap => {
            if (userSnap) {
                getUserMore({id:userSnap.uid, email:userSnap.email})
            }
        });
    }, [])
    
    return (
        <AuthContext.Provider
            value={{
                auth,
                user,
                setUser,
                gToken,
                setGToken,
            }}
            >
            {children}
        </AuthContext.Provider>
    )
};

export default AuthProvider;