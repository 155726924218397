import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import { collection, getDocs, orderBy, query, where } from '@firebase/firestore';
import { db } from '../App';
import { useHistory } from 'react-router';
import { UserContext } from '../context/UserContext';

const CustomInput = ({type, placeholder, size, value, isDisabled, onChange, label}) => {
    const [focused, setFocused] = useState(false);
    const {language} = useContext(UserContext)
    return(
        <div style={{marginBottom:25, display:isDisabled ? 'none' : undefined}}>
            <div className={"col-md-1"}></div>
            <div className={"col-md-" + size}>
                {type === 'file'? 
                <label className="btn btn-primary" style={{borderRadius:10}}>
                    {language==='fr'? 'Choisir un fichier':'Choose a file'}
                <input
                    hidden
                    disabled={isDisabled}
                    onChange={onChange}
                    placeholder={placeholder}
                    style={{borderRadius:10, height:38, borderColor:focused ? 'blue' : '#cccccc', borderWidth: focused ? 2:1, color:isDisabled? '#677' :'black'}}
                    type={type ? type : "text"}
                    
                    className="form-control shadow-none"
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    value={value}
                    />
                    </label>
                    :
                    <label style={{display:'flex', flex:1, flexDirection:'column'}}>
                        {label ? 
                        <span style={{marginTop:-13, marginBottom:3, marginLeft:6,fontSize:16.5}}>
                            {label}
                        </span>
                        : null
                        }
                        <input
                        disabled={isDisabled}
                        onChange={onChange}
                        placeholder={placeholder}
                        style={{borderRadius:10, height:38, borderColor:focused ? 'blue' : '#cccccc', borderWidth: focused ? 2:1, color:isDisabled? '#677' :'black'}}
                        type={type ? type : "text"}
                        id="form12"
                        className="form-control shadow-none"
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        value={value}
                        />
                    </label>
                    }
            </div>
            <div className={"col-md-" + 11-size}></div>
            
        </div>
    )
}

function customTheme (theme) {
    return {
        ...theme,
        borderRadius:10,
        borderWidth:5,
        colors:{
            ...theme.colors,
            primary25: '#cccccc',
            primary:'green'
        }
    }
}

const CustomSelect = ({placeholder, size, options, isDisabled, value, setState, noOptionsMessage}) => {
    console.log(value)
    return(
    <div style={{marginBottom:25, display: isDisabled ? 'none' : undefined}}>
        <div className={"col-md-1"}></div>
        <div className={"col-md-" + size}>
            <Select
                isDisabled={isDisabled}
                noOptionsMessage={noOptionsMessage}
                options={ options }
                placeholder={placeholder}
                theme={customTheme}
                onChange={setState}
                value={value}
                />
        </div>
        <div className={"col-md-"+ 11-size}></div>
        
    </div>)
}


const Search = () => {
    const {language} = useContext(UserContext);
    
    const [dBDocs, setDBDocs] = useState(null);
    const [organisation, setOrganisation] = useState('');
    const [period, setPeriod] = useState('');
    const [category, setCategory] = useState('');
    const [domain, setDomain] = useState('');
    const [OIType, setOIType] = useState('');
    const [reportType, setReportType] = useState('');
    const [country, setCountry] = useState('');
    const [docTitle, setDocTitle] = useState('');
    const [concernedTitle, setConcernedTitle] = useState('');
    const [docEditor, setDocEditor] = useState('');
    const [journal, setJournal] = useState('');
    const [fromValidityPeriod, setFromValidityPeriod] = useState('');
    const [toValidityPeriod, setToValidityPeriod] = useState('');
    const [publicationDate, setPublicationDate] = useState('');
    
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    function isInputAllowed (type) {
        return category && dBDocs[language].placeholders[type].allowed.includes(category.value);
    } 

    function isSelectAllowed (type) {
        return category && dBDocs[language][type].allowed.includes(category.value);
    }
    
    async function getFormParams () {
        let uploadParams = {};
        const q = query(collection(db, "uploadparams"));

        const params = await getDocs(q);
        params.forEach(lang => {
            uploadParams[lang.id] = lang.data()
        });
        
        if(Object.keys(uploadParams).length ===0) {
            uploadParams = null;
        }

        setDBDocs(uploadParams);
    };

    const getCorrespondingDocs = async() => {
    let validatedDocsArr = [];
    const q = query(
        collection(db, "resources/documents/validated"),
        orderBy('createdAt', 'desc')
    //   where('country', '==', '*'),
    );
    
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
    validatedDocsArr.push({...doc.data(), id: doc.id})
    });

    
    return validatedDocsArr;
    };

    
    const onFormSubmit = async () => {
        const condition = category||docTitle || country||organisation||period||
        (isSelectAllowed('domain') && domain)||
        (isSelectAllowed('OIType') && OIType)||
        (isSelectAllowed('reportType') && reportType)||
        (isSelectAllowed('reportType') && reportType)||
        (isInputAllowed('editor') && docEditor)||
        (isInputAllowed('journal')&& journal)||
        (isInputAllowed('validityPeriod') && fromValidityPeriod)||
        (isInputAllowed('validityPeriod') && toValidityPeriod)||
        (isInputAllowed('publicationDate') && publicationDate)
        ?true:false;


        if (condition) {
            setLoading(true);

            let filterObj = {};
            if (category) filterObj.category = (docs) => docs.filter(doc => doc.category.includes(category.value));
            if (docTitle) filterObj.title = (docs) => docs.filter(doc => doc.title.toLowerCase().includes(docTitle.toLowerCase()));
            if (country) filterObj.country = (docs) => docs.filter(doc => doc.country.includes(country.value));
            if (organisation) filterObj.organisation = (docs) => docs.filter(doc => doc.organisation.includes(organisation.value));
            if (period) filterObj.period = (docs) => docs.filter(doc => doc.period.includes(period.value));
            if (isSelectAllowed('domain') && domain) filterObj.domain = (docs) => docs.filter(doc => doc.domain.includes(domain.value));
            if (isSelectAllowed('OIType') && OIType) filterObj.OIType = (docs) => docs.filter(doc => doc.OIType.includes(OIType.value));
            if (isSelectAllowed('reportType') && reportType) filterObj.reportType = (docs) => docs.filter(doc => doc.reportType.includes(reportType.value));
            if (isInputAllowed('concernedTitles') && concernedTitle) filterObj.concernedTitles = (docs) => docs.filter(doc => doc.concernedTitles.toLowerCase().includes(concernedTitle.toLowerCase()));
            if (isInputAllowed('editor') && docEditor) filterObj.editor = (docs) => docs.filter(doc => doc.editor.toLowerCase().includes(docEditor.toLowerCase()));
            if (isInputAllowed('journal')&& journal) filterObj.journal = (docs) => docs.filter(doc => doc.journal.toLowerCase().includes(journal.toLowerCase()));
            if (isInputAllowed('validityPeriod') && fromValidityPeriod) filterObj.fromValidityPeriod = (docs) => docs.filter(doc => doc.fromValidityPeriod >= fromValidityPeriod);
            if (isInputAllowed('validityPeriod') && toValidityPeriod) filterObj.toValidityPeriod = (docs) => docs.filter(doc => doc.toValidityPeriod <= toValidityPeriod);
            if (isInputAllowed('publicationDate') && publicationDate) filterObj.publicationDate = (docs) => docs.filter(doc => doc.publicationDate == publicationDate);

            
            const resultDocs = await getCorrespondingDocs();
            let filteredDocs = resultDocs;
            Object.keys(filterObj).forEach(key => filteredDocs= filterObj[key](filteredDocs));
            setLoading(false);
            // console.log('Filtered docs: ', filteredDocs)
                history.push({
                pathname:'./results',
                state:{documents:filteredDocs}
            });
        } else {
            alert(language==='fr'?'Veuillez remplir au moins un filtre.':'Please, set at least one filter.');
        }
    }


    let periodOptions = [];
    if(dBDocs) {
        const initPer = dBDocs.en.period.initialPeriod;
        const finalPer = dBDocs.en.period.finalPeriod;
        for (let i =initPer; i<= finalPer; i++){
            periodOptions.push({label: i.toString(), value:i})
        }
    }
    
    const partTitle = language==='fr'?" (partiel ou complet)":' (partial or full)'
    useEffect(() => {
        setOrganisation('');
    }, [country]);

    useEffect(() => {
        setDomain('');
        setOIType('');
        setReportType('');
    }, [category]);

    useEffect(() => {
        console.log('Current document: ', document)
    }, [document]);

    useEffect(() => {
        getFormParams();
    }, []);
    return (
        <div>
            <h1 style={{marginBottom:40, marginTop:20}} >Rechercher un document</h1>
            <div className="row">
                <CustomInput
                    onChange={(e) => setDocTitle(e.target.value)}
                    value={docTitle}
                    placeholder={dBDocs? dBDocs[language].placeholders.title.value+partTitle:''}
                    size={4}
                />

                {/* <div style={{marginBottom:25}}>
                    <div className={"col-md-1"}></div>
                    <div className={"col-md-4"}>
                        <Select
                            noOptionsMessage={() =>"Aucune option dispoinble"}
                            options={ dBDocs[language].country }
                            placeholder={"Pays"}
                            theme={customTheme}
                            onChange={({value}) => {
                                setCountry(value);
                            }}
                            />
                    </div>
                    <div className={"col-md-7"}></div>
                    
                </div> */}
                <CustomSelect
                    type="country"
                    placeholder={language==='fr'?'Pays':'Country'}
                    size={4}
                    options={dBDocs? dBDocs[language].country:[]}
                    value={country}
                    setState={(value) => {
                        setCountry(value);
                        setOrganisation('');
                    }}
                />
                {country ? (
                    <CustomSelect
                        type="organisation"
                        placeholder="Organisation"
                        size={5}
                        options={dBDocs? dBDocs[language].organisation[country.value]:[]}
                        value={organisation}
                        setState={(value) => setOrganisation(value)}
                    />
                ) : (
                    <CustomSelect
                        type="organisation"
                        placeholder="Organisation"
                        size={5}
                        noOptionsMessage={() =>"Veuillez selectionner un pays"}
                        // options={dBDocs[language].organisation.CMR}
                    />
                )
                    
                }

                <CustomSelect
                    setState={(value) => setPeriod(value)}
                    value={period}
                    placeholder={language==='fr'?'Période':'Period'}
                    size={4}
                    options={periodOptions}
                />
                <CustomSelect
                    type="category"
                    placeholder={language==='fr'?'Catégorie':'Category'}
                    size={5}
                    options={dBDocs? dBDocs[language].category:[]}
                    value={category}
                    setState={(value) => setCategory(value)}
                />
                <CustomSelect
                    type="domain"
                    placeholder={language==='fr'?"Domaine d'intervention":'Intervention domain'}
                    size={5}
                    isDisabled={!isSelectAllowed('domain')}
                    options={dBDocs? dBDocs[language].domain.values:[]}
                    value={domain}
                    setState={(value) => setDomain(value)}
                />
                <CustomSelect
                    type="OIType"
                    placeholder={language==='fr'? "Type d'OI":'OI type'}
                    size={5}
                    isDisabled={!isSelectAllowed('OIType')}
                    options={dBDocs? dBDocs[language].OIType.values:[]}
                    value={OIType}
                    setState={(value) => setOIType(value)}
                />
                <CustomSelect
                    type="reportType"
                    placeholder={language==='fr'?'Type de rapport':'Report type'}
                    size={5}
                    isDisabled={!(category && dBDocs[language].reportType.allowed.includes(category.value))}
                    options={dBDocs? dBDocs[language].reportType.values[category.value]:[]}
                    value={reportType}
                    setState={(value) => setReportType(value)}
                />
                <CustomInput
                    onChange={(e) => setConcernedTitle(e.target.value)}
                    value={concernedTitle}
                    placeholder={dBDocs? dBDocs[language].placeholders.concernedTitles.value:''}
                    size={4}
                    isDisabled={!isInputAllowed('concernedTitles')}
                />
                <CustomInput
                    onChange={(e) => setDocEditor(e.target.value)}
                    value={docEditor}
                    placeholder={dBDocs? dBDocs[language].placeholders.editor.value:''}
                    size={3}
                    isDisabled={!isInputAllowed('editor')}
                />
                <CustomInput
                    onChange={(e) => setJournal(e.target.value)}
                    value={journal}
                    placeholder={dBDocs? dBDocs[language].placeholders.journal.value:''}
                    size={4}
                    isDisabled={!isInputAllowed('journal')}
                />
                <CustomInput
                    type="date"
                    onChange={(e) => setFromValidityPeriod(e.target.value)}
                    value={fromValidityPeriod}
                    label={'Valide du'}
                    size={4}
                    isDisabled={!isInputAllowed('validityPeriod')}
                />
                <CustomInput
                    type="date"
                    onChange={(e) => setToValidityPeriod(e.target.value)}
                    value={toValidityPeriod}
                    label={'au'}
                    size={4}
                    isDisabled={!isInputAllowed('validityPeriod')}
                />
                <CustomInput
                    type='date'
                    onChange={(e) => setPublicationDate(e.target.value)}
                    value={publicationDate}
                    placeholder={dBDocs? dBDocs[language].placeholders.publicationDate.value:''}
                    label={language=='fr'?'Date de publication':'Publication date'}
                    size={4}
                    isDisabled={!isInputAllowed('publicationDate')}
                />
            
                <div style={{marginBottom:25}}>
                    <div className={"col-md-1"}></div>
                    <div className={"col-md-4"} style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <button onClick={onFormSubmit} type="button" className="btn btn-primary shadow-none" style={{border:'none', width:140, backgroundColor:'#1C7D2D', borderRadius:10, marginTop:15}}>Rechercher</button>
                        {loading? <div style={{color:'#1C7D2D', height:25, width:25, marginTop:15, marginLeft:30}} class="spinner-border"/> :null}
                    </div>
                    <div className={"col-md-7"}></div>
                    
                </div>
                <span style={{marginBottom:30}}>
                {language==='fr'?'Recherchez un document en renseignant un ou plusieurs des champs ci dessus.'
                :'Search a document by filling one or more of the upper fields.'}
                </span>
                {/* <span>
                    Vous pouvez aussi obtenir une image Excel de la base de documents en cliquant
                </span> */}
            </div>
        </div>
    );
} 

export default Search;