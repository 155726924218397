import React, { useContext } from "react";
import { Link, useLocation} from "react-router-dom";
import styled from "styled-components";
import { UserContext } from "../context/UserContext";

export default function Logged () {
    const params = useLocation().state;
    const {language} = useContext(UserContext);
    // console.log('All the params: ', params);
    return (
        <Container>
            <span style={{fontSize:25, margin:20, color:'#0275d8'}}>
                {params.type === 'register' ?'Compte crée avec succès' : 'Connecté à votre compte avec succès'}
            </span>
            <span style={{fontSize:23, textAlign:'center', marginBottom:22}}>
            {language==='fr'? "Retournez à l'ecran d'accueil pour continuer à profiter de la plateforme O2I - TRI"
                :'Go back to the homescreen to continue enjoying O2I-TRI platform'
            }
            </span>
            <Link to="/">
              <div style={{minWidth:110, fontSize:20, fontWeight:'650'}} className="btn btn-primary shadow-none">
                OK
              </div>
            </Link>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction:column;
    height: 68vh;
    align-items: center;
    justify-content: center;
`