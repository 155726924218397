import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import UploadParams from '../assets/UploadParams';
import {collection, addDoc, getDocs, doc, query, setDoc} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from '../App';
import { UserContext } from '../context/UserContext';
import { AuthContext } from '../context/AuthContext';
import mime from 'mime-types';
import {BsGearFill} from 'react-icons/bs';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const CustomInput = ({type, placeholder, size, value, isDisabled, onChange, label}) => {
    const [focused, setFocused] = useState(false);
    const {language} = useContext(UserContext);
    return(
        <div style={{marginBottom:25, display:isDisabled ? 'none' : undefined}}>
            <div className={"col-md-1"}></div>
            <div className={"col-md-" + size}>
                {type === 'file'? 
                <label aria-disabled={isDisabled} className="btn btn-primary" style={{borderRadius:10}}>
                    {language==='fr'?'Choisir un fichier':'Choose a file'}
                <input
                    accept=".pdf,.doc,.docx,.txt, ppt,.pptx,.xls,.xlsx"
                    hidden
                    disabled={isDisabled}
                    onChange={onChange}
                    placeholder={placeholder}
                    style={{borderRadius:10, height:38, borderColor:focused ? 'blue' : '#cccccc', borderWidth: focused ? 2:1, color:isDisabled? '#677' :'black'}}
                    type={type ? type : "text"}
                    
                    className="form-control shadow-none"
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    value={value}
                    />
                    </label>
                    :
                    <label style={{display:'flex', flex:1, flexDirection:'column'}}>
                        {label ? 
                        <span style={{marginTop:-13, marginBottom:3, marginLeft:6,fontSize:16.5}}>
                            {label}
                        </span>
                        : null
                        }
                        <input
                        disabled={isDisabled}
                        onChange={onChange}
                        placeholder={placeholder}
                        style={{borderRadius:10, height:38, borderColor:focused ? 'blue' : '#cccccc', borderWidth: focused ? 2:1, color:isDisabled? '#677' :'black'}}
                        type={type ? type : "text"}
                        id="form12"
                        className="form-control shadow-none"
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        value={value}
                        />
                    </label>
                    }
            </div>
            <div className={"col-md-" + 11-size}></div>
            
        </div>
    )
}

function customTheme (theme) {
    return {
        ...theme,
        borderRadius:10,
        borderWidth:5,
        colors:{
            ...theme.colors,
            primary25: '#cccccc',
            primary:'green'
        }
    }
}

export const CustomSelect = ({placeholder, size, options, isDisabled, value, setState, noOptionsMessage}) => {
    return(
    <div style={{marginBottom:25, display: isDisabled ? 'none' : undefined}}>
        <div className={"col-md-1"}></div>
        <div className={"col-md-" + size}>
            <Select
                isDisabled={isDisabled}
                noOptionsMessage={noOptionsMessage}
                options={ options }
                placeholder={placeholder}
                theme={customTheme}
                onChange={setState}
                value={value}
                />
        </div>
        <div className={"col-md-"+ 11-size}></div>
        
    </div>)
}


const Upload = () => {
    const {language} = useContext(UserContext);
    const {user} = useContext(AuthContext);

    const [dBDocs, setDBDocs] = useState(null);
    const [organisation, setOrganisation] = useState('');
    const [period, setPeriod] = useState('');
    const [category, setCategory] = useState('');
    const [domain, setDomain] = useState('');
    const [OIType, setOIType] = useState('');
    const [reportType, setReportType] = useState('');
    const [country, setCountry] = useState('');
    const [docTitle, setDocTitle] = useState('');
    const [concernedTitle, setConcernedTitle] = useState('');
    const [docEditor, setDocEditor] = useState('');
    const [journal, setJournal] = useState('');
    const [fromValidityPeriod, setFromValidityPeriod] = useState('');
    const [toValidityPeriod, setToValidityPeriod] = useState('');
    const [publicationDate, setPublicationDate] = useState('');
    const [document, setDocument] = useState(null);
    const [loading, setLoading] = useState(false);
    // const filePlaceholder = document ? document.value.match(/[\/\\]([\w\d\s\.\-\(\)]+)$/).slice(-1)[0] :'Aucun document selectionné';
    const history = useHistory();

    const filePlaceholder = document ? document.name : language==='fr'?'Aucun document selectionné':'No document choosen';


    async function getFormParams () {
        let uploadParams = {};
        const q = query(collection(db, "uploadparams"));

        const params = await getDocs(q);
        params.forEach(lang => {
            uploadParams[lang.id] = lang.data()
        });
        
        if(Object.keys(uploadParams).length ===0) {
            uploadParams = null;
        }

        setDBDocs(uploadParams);
    }
    function isInputAllowed (type) {
        return category && dBDocs && dBDocs[language].placeholders[type].allowed.includes(category.value);
    }

    function isSelectAllowed (type) {
        return category && dBDocs && dBDocs[language][type].allowed.includes(category.value);
    }
    
    // const onFormSubmit = async () => {
    //     await setDoc(doc(db, 'uploadparams', 'fr'), {...UploadParams.fr});
    //     await setDoc(doc(db, 'uploadparams', 'en'), {...UploadParams.en});
    //     alert('Upload params loaded succesfully');
    // };

    const clearForm = () => {
        setDocTitle('');
        setPeriod('');
        setCategory('');
        setConcernedTitle('');
        setDocEditor('');
        setJournal('');
        setFromValidityPeriod('');
        setToValidityPeriod('');
        setPublicationDate('');
        setDocument(null);
    };

    const onFormSubmit = () => {
        const saveDocument = async(document, category, properties) => {
            if(!loading) {
                setLoading(true);
                const storage = getStorage();
                const currentDate = new Date();
                const fileName = properties.title.split('.').join('_') + currentDate.toISOString() + '.' + filePlaceholder.split('.').pop()
                const storageRef = ref(storage, `documents/${fileName}`);
                await uploadBytes(storageRef, document);
                const downloadUrl = await getDownloadURL(storageRef);
                console.log('Download Url: ',  downloadUrl);
                await addDoc(collection(db, 'resources','documents', 'waiting'), {...properties, docUrl:downloadUrl, category, createdAt: new Date(), createdBy:user.id});
                setLoading(false);
                clearForm();
                alert(language==='fr'?"Document chargé avec succès":'Document uploaded successfully');
            }
        }

        const condition = 
            docTitle
            &&country 
            &&organisation
            && period
            && category
            && (!isSelectAllowed('domain') || domain)
            && (!isSelectAllowed('OIType') || OIType)
            && (!isSelectAllowed('reportType') || reportType)
            && (!isInputAllowed('concernedTitles') || concernedTitle)
            && (!isInputAllowed('editor') || docEditor)
            && (!isInputAllowed('journal') || journal)
            && (!isInputAllowed('validityPeriod') || (fromValidityPeriod && toValidityPeriod))
            && (!isInputAllowed('publicationDate') || publicationDate)
            && document           

        if (condition) {
            let docObj = {};
            docObj.title = docTitle;
            docObj.country = country.value;
            docObj.organisation = organisation.value;
            docObj.period = period.value;
            docObj.type = mime.extension(document.type);
            docObj.loaderEmail = user.email;
            if (isSelectAllowed('domain')) docObj.domain = domain.value;
            if (isSelectAllowed('OIType')) docObj.OIType = OIType.value;
            if (isSelectAllowed('reportType')) docObj.reportType = reportType.value;
            if (isInputAllowed('concernedTitles')) docObj.concernedTitles = concernedTitle;
            if (isInputAllowed('editor')) docObj.editor = docEditor;
            if (isInputAllowed('journal')) docObj.journal = journal;
            if (isInputAllowed('validityPeriod')) docObj.fromValidityPeriod = fromValidityPeriod;
            if (isInputAllowed('validityPeriod')) docObj.toValidityPeriod = toValidityPeriod;
            if (isInputAllowed('publicationDate')) docObj.publicationDate = publicationDate;

            try {
                saveDocument(document, category.value, docObj);
            } catch (error) {
                setLoading(false);
                console.log('Error while saving the document: ', error)
            }
        } else {
            alert(language==='fr'?'Un ou plusieurs champs sont vides, vérifiez encore.':'One or more fields are empty, please check again');
        }
    }


    let periodOptions = [];
    if(dBDocs) {
        const initPer = dBDocs.en.period.initialPeriod;
        const finalPer = dBDocs.en.period.finalPeriod;
        for (let i =initPer; i<= finalPer; i++){
            periodOptions.push({label: i.toString(), value:i})
        }
    }

    useEffect(() => {
        getFormParams();
    }, [])
    useEffect(() => {
        setOrganisation('');
    }, [country])

    useEffect(() => {
        setDomain('');
        setOIType('');
        setReportType('');
    }, [category]);

    
    // if(!dBDocs) {
    //     return null;
    // }
    return (
        <div>
            <Header >
                <h1>
                    {language==='fr'?'Charger un document':'Upload a document'}
                </h1>
                {user.admin?
                    <div className="formSettings" >
                        <BsGearFill onClick={() => history.push('formupgrade')} size={26}/>
                    </div>
                    :
                    null    
                }
            </Header>
            <div className="row">
                <CustomInput
                    onChange={(e) => setDocTitle(e.target.value)}
                    value={docTitle}
                    placeholder={dBDocs? dBDocs[language].placeholders.title.value:''}
                    size={4}
                />

                {/* <div style={{marginBottom:25}}>
                    <div className={"col-md-1"}></div>
                    <div className={"col-md-4"}>
                        <Select
                            noOptionsMessage={() =>"Aucune option dispoinble"}
                            options={ dBDocs[language].country }
                            placeholder={"Pays"}
                            theme={customTheme}
                            onChange={({value}) => {
                                setCountry(value);
                            }}
                            />
                    </div>
                    <div className={"col-md-7"}></div>
                    
                </div> */}
                <CustomSelect
                    placeholder={language==='fr'?"Pays":'Country'}
                    size={4}
                    options={dBDocs? dBDocs[language].country:[]}
                    value={country}
                    setState={(value) => {
                        setCountry(value);
                        setOrganisation('');
                    }}
                />
                {country ? (
                    <CustomSelect
                        type="organisation"
                        placeholder="Organisation"
                        size={5}
                        options={dBDocs? dBDocs[language].organisation[country.value]:[]}
                        value={organisation}
                        setState={(value) => setOrganisation(value)}
                    />
                ) : (
                    <CustomSelect
                        type="organisation"
                        placeholder="Organisation"
                        size={5}
                        noOptionsMessage={() =>"Veuillez selectionner un pays"}
                        // options={dBDocs[language].organisation.CMR}
                    />
                )
                    
                }

                <CustomSelect
                    setState={(value) => setPeriod(value)}
                    value={period}
                    placeholder={language==='fr'? 'Période':'Period'}
                    size={4}
                    options={periodOptions}
                />
                <CustomSelect
                    type="category"
                    placeholder={language==='fr'? "Catégorie":'Category'}
                    size={5}
                    options={dBDocs? dBDocs[language].category:[]}
                    value={category}
                    setState={(value) => setCategory(value)}
                />
                <CustomSelect
                    type="domain"
                    placeholder={language==='fr'?"Domaine d'intervention":'Intervention domain'}
                    size={5}
                    isDisabled={!isSelectAllowed('domain')}
                    options={dBDocs? dBDocs[language].domain.values:[]}
                    value={domain}
                    setState={(value) => setDomain(value)}
                />
                <CustomSelect
                    type="OIType"
                    placeholder={language==='fr'? "Type d'OI":'OI type'}
                    size={5}
                    isDisabled={!isSelectAllowed('OIType')}
                    options={dBDocs? dBDocs[language].OIType.values:[]}
                    value={OIType}
                    setState={(value) => setOIType(value)}
                />
                <CustomSelect
                    type="reportType"
                    placeholder={language==='fr'? "Type de rapport":'Report type'}
                    size={5}
                    isDisabled={!(category && isSelectAllowed('reportType'))}
                    options={dBDocs? dBDocs[language].reportType.values[category.value]:[]}
                    value={reportType}
                    setState={(value) => setReportType(value)}
                />
                <CustomInput
                    onChange={(e) => setConcernedTitle(e.target.value)}
                    value={concernedTitle}
                    placeholder={dBDocs? dBDocs[language].placeholders.concernedTitles.value:''}
                    size={4}
                    isDisabled={!isInputAllowed('concernedTitles')}
                />
                <CustomInput
                    onChange={(e) => setDocEditor(e.target.value)}
                    value={docEditor}
                    placeholder={dBDocs? dBDocs[language].placeholders.editor.value:''}
                    size={3}
                    isDisabled={!isInputAllowed('editor')}
                />
                <CustomInput
                    onChange={(e) => setJournal(e.target.value)}
                    value={journal}
                    placeholder={dBDocs? dBDocs[language].placeholders.journal.value:''}
                    size={4}
                    isDisabled={!isInputAllowed('journal')}
                />
                <CustomInput
                    type="date"
                    onChange={(e) => setFromValidityPeriod(e.target.value)}
                    value={fromValidityPeriod}
                    label={'Valide du'}
                    size={4}
                    isDisabled={!isInputAllowed('validityPeriod')}
                />
                <CustomInput
                    type="date"
                    onChange={(e) => setToValidityPeriod(e.target.value)}
                    value={toValidityPeriod}
                    label={'au'}
                    size={4}
                    isDisabled={!isInputAllowed('validityPeriod')}
                />
                <CustomInput
                    type='date'
                    onChange={(e) => setPublicationDate(e.target.value)}
                    value={publicationDate}
                    placeholder={dBDocs? dBDocs[language].placeholders.publicationDate.value:''}
                    label={language=='fr'?'Date de publication':'Publication date'}
                    size={4}
                    isDisabled={!isInputAllowed('publicationDate')}
                />
            
                <CustomInput
                    isDisabled={loading}
                    type='file'
                    onChange={(e) => setDocument(e.target.files[0])}
                    // value={document}
                    placeholder={language==='fr'? 'Choisir un fichier':'Choose a file'}
                    size={4}
                />
                {<span style={{marginTop:-10, marginBottom:10}}>{filePlaceholder}</span>}
                <div style={{marginBottom:25}}>
                    <div className={"col-md-1"}></div>
                    <div className={"col-md-4"} style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <button disabled={loading} onClick={onFormSubmit} type="button" className="btn btn-success shadow-none" style={{width:140, borderRadius:10, marginTop:15}}>{language == 'fr'?'Charger':'Upload'}</button>
                        {loading?<div style={{color:'#1C7D2D', height:25, width:25, marginTop:15, marginLeft:30}} class="spinner-border"/>:null}
                    </div>
                    <div className={"col-md-7"}></div>
                    
                </div>
            </div>
        </div>
    );
} 

export default Upload;

const Header = styled.div`
    margin-bottom:40px;
    margin-top:20px;
    display:flex;
    flex-direction:row;
    align-items:center;
    .formSettings {
        margin-left:50px;
        border-radius:50%;
        padding:3px;
        transition: 0.1s ease-in-out;
        &:hover{
        background-color:rgba(0, 0, 0, 0.2); 
        }
    }
`