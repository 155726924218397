module.exports = {    
    en: {
        admin:{
            card1Title:'Users',
            card1Subtitle:'users currently registered on the platform',
            card2Title:'Waiting',
            card2Subtitle:'documents waiting for validation',
            card3Title:'Documents base',
            card3Subtitle:'documents available to the public',
            usersContainer:'Users',
            adminsContainer:'Admins',
            waitingContainer:'Waiting documents',
            publicDocs:'Public documents',
            editingDocs:"Documents being edited",
            createDoc:'Create a document'
        },
        hero:{
            welcome:'Welcome to the O2I-TRI platform',
            description:'The online database of the independant observers. Here you will find diverse Independant Observers documents, all being downloadable...',
            searchTitle:'Title (partial or full)',
            searchTitlePlaceholder:'Search by title',
            fromDateLabel:'From the',
            toDateLabel:"To",
            search:'Search'
        },
        navbar: {
            home:'Home',
            search:'Search',
            upload:'Upload a document',
            seeall:'See All'
        },
        services: {
            'service1Title':'Documents Hub',
            'service1Subtitle':'Online databse for PA-OI documents',
            'service2Title':"Exchange Platform",
            'service2Subtitle':"Upload and download PA-OI related documents here",
            'service3Title':'Instant Access',
            'service3Subtitle':'Access instantly to the most recents documents',
            'service4Title':'Find the document',
            'service4Subtitle':"Find the document on the platform, based on it's name, category, a date...",
        },
    },
    fr: {
        admin:{
            card1Title:'Utilisateurs',
            card1Subtitle:'utilisateurs actuellement enregistrés sur la plateforme',
            card2Title:'En attente',
            card2Subtitle:'documents en attente de validation',
            card3Title:'Base de documents',
            card3Subtitle:'documents au total disponibles au public',
            usersContainer:'Utilisateurs',
            adminsContainer:'Administrateurs',
            waitingContainer:'Documents en attente',
            publicDocs:'Documents publiques',
            editingDocs:"Documents en cours d'édition",
            createDoc:'Créer un document'
        },
        hero:{
            welcome:'Bienvenue sur la plateforme O2I-TRI',
            description:"La base de données  en ligne des observateurs indépendants. Vous trouverez ici une diversité de documents d'OI téléchargeables...",
            searchTitle:'Titre (partiel ou complet)',
            searchTitlePlaceholder:'Rechercher par le titre',
            fromDateLabel:'A partir du',
            toDateLabel:"Jusqu'au",
            search:'Rechercher'
        },
        navbar: {
            home:'Accueil',
            search:'Rechercher',
            upload:'Charger un document',
            seeall:'Voir tout'
        },
        services: {
            'service1Title':'Hub documentaire',
            'service1Subtitle':'Base de donnée en ligne pour les documents de la PA-OI',
            'service2Title':"Plateforme d'échange",
            'service2Subtitle':"Chargez et téléchargez des documents concernant la PA-OI sur la Plateforme",
            'service3Title':'Accès instantané',
            'service3Subtitle':'Accedez instantanément aux documents les plus récents',
            'service4Title':'Trouvez le document',
            'service4Subtitle':"Trouvez le document sur la plateforme, a partir de son nom, de sa catégorie, d'une date...",
        },
    },
}