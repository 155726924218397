import React, { useContext, useState } from "react";
import styled from "styled-components";
import { UserContext } from "../context/UserContext";

import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { collection, getDocs, orderBy, query, where } from "@firebase/firestore";
import { db } from "../App";
import { useHistory } from "react-router";
import UploadParams from "../assets/UploadParams";

const categories = {
  en:[
      {
          title:'Strategic documents',
          description:"Action plan, strategy, charter...",
          value:'SD'
      },
      {
          title:'Contratcual documents',
          description:"Contract, protocol of agreement, mandate/terms of reference of the OI, act creating CDLs",
          value:'CD'
      },
      {
          title:"OI mission reports",
          description:"Conjoints, independent, accompanied, verification and others missions",
          value:'OI-R'
      },
      {
          title:"OI technical synthesis",
          description:"Analysis note, information note, synthese note of OI reports",
          value:'OI-TS'
      },
      {
          title:"Periodical reports",
          description:"Thematic report, activity and narrative report",
          value:'PR'
      },
      {
          title:"OI Manuals / guides",
          description:"OIEs, OIMs...",
          value:'OI-G'
      },
      {
          title:"Validation meetings",
          description:"CR/PV Lecture commitees/ Ad-hoc commitees",
          value:'CR-PV'
      },
      {
          title:"Press Articles",
          description:"Workshop, press conference, general information",
          value:'PA'
      },
      {
          title:"Scientific publications on the OI",
          description:"Thematic",
          value:'SP-OI'
      }
  ],
  fr:[
  {
      title:'Documents stratégiques',
      description:"Plan d'action, stratégie, charte...",
      value:'SD'
  },
  {
      title:'Documents contractuels',
      description:"Contrat, protocole d'accord, Mandat/Termes de référence de l'OI, acte créant les CDL",
      value:'CD'
  },
  {
      title:"Rapports de missions d'OI",
      description:"Missions conjointes, indépendantes, accompagnées, vérification et autres...",
      value:'OI-R'
  },
  {
      title:"Synthèses techniques sur l'OI",
      description:"Note d'analyse, note d'information, Note de synthèse des rapports d'OI",
      value:'OI-TS'
  },
  {
      title:"Rapports périodiques",
      description:"Rapport de thématique, rapport d'activité et narratif",
      value:'PR'
  },
  {
      title:"Manuels / guides d'OI",
      description:"OIEs, OIMs...",
      value:'OI-G'
  },
  {
      title:"Réunions de validation",
      description:"CR/PV comités de lecture / comités ad hoc",
      value:'CR-PV'
  },
  {
      title:"Articles de presse",
      description:"Atelier, conférence de presse, information générale",
      value:'PA'
  },
  {
      title:"Publications scientifiques sur l'OI",
      description:"Thématique",
      value:'SP-OI'
  }
]};

export default function Services() {
  const {language} = useContext(UserContext);
  const history = useHistory();
  const [loadingCategory, setLoadingCategory] = useState(null);

  const getCategoryDocs = async(category) => {
    setLoadingCategory(category);
    let categoryDocs = [];
    const q = query(
        collection(db, "resources/documents/validated"),
        orderBy('createdAt', 'desc'),
        where('category', '==', category ),
    );
    
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
    categoryDocs.push({...doc.data(), id: doc.id});
    });

    setLoadingCategory(null);
    history.push({
        pathname:'./results',
        state:{documents:categoryDocs, title:UploadParams[language].category.filter(item => item.value === category)[0].label}
    });
    };
  return (
      <>
        <div className="container">
            <h1 style={{marginTop:30, marginBottom:40}}>{language==='fr'?'Parcourez les catégories':'Go through the categories'}</h1>
        </div>
        <Section id="services">
          {categories[language].map((category, index) => {
            return (
              <div onClick={() => getCategoryDocs(category.value)} className={"service"+` service-${(index%3+(index-index%3)/3)%3}`}>
                <h3>{category.title}</h3>
                <p>{category.description}</p>
                {loadingCategory===category.value?
                  <div style={{color:'#1C7D2D', padding:5, height:33, width:33}} class="spinner-border more"/>
                  :
                  <BsFillArrowRightCircleFill className="more" size={30}/>
                }
              </div>
            )
          })}
        </Section>
    </>
  );
}

const Section = styled.section`
  padding-bottom: 5rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  .service {
    position:relative;
    display: flex;
    flex-direction: column;
    /* color:white; */
    gap: 1rem;
    border-radius:20px;
    padding-top: 1.5rem;
    padding-left:1.5rem;
    padding-right:1.5rem;
    /* background-color: rgba(133, 135, 137, 0.3); */
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    .more {
        position:absolute;
        right:10px;
        bottom:10px;
        transition: 0.3s ease-out;
        border-radius:50%;
    }
    &:hover {
      background-color: rgba(133, 135, 137, 0.3);
      cursor:pointer;
      .more {
        box-shadow:rgba(256, 256, 256, 1) -10px -7px 49px 30px;
        color:rgba(28, 125, 45, 1);
        height:38px;
        width:38px;
      }
    }
  }
  .service-0{
    background-color:#006AB3;
    color:white;
  }
  .service-1{
    background-color:#1C7D2D;
    color:white;
  }
  .service-2{
    color:white;
    background-color:#858789;
  }
  @media screen and (min-width: 280px) and (max-width: 720px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
