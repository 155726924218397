import React, { useContext, useEffect } from "react";
import Home from "./pages/Home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Upload from "./pages/Upload";
import Navbar from './components/Navbar';
import Footer from "./components/Footer";
import Register from "./pages/Register"
import Login from "./pages/Login";
import Logged from "./pages/Logged";
import Admin from "./pages/Admin";
import Search from "./pages/Search";
import { initializeApp } from "firebase/app";
import { AuthContext } from "./context/AuthContext";
import { getFirestore } from "@firebase/firestore";
import Results from "./pages/Results";
import PageNotFound from "./pages/PageNotFound";
import CreateDoc from "./pages/CreateDoc";
import SeeAll from "./pages/SeeAll";
import Statistics from "./pages/Statistics";
import RequireAuth from "./pages/RequireAuth";
import FormUpgrade from "./pages/FormUpgrade";
import { getAuth } from "@firebase/auth";
import { GlobalDebug } from "./utilis/remove-consoles";
import EditDoc from "./pages/EditDoc";


const firebaseConfig = {
  apiKey: "AIzaSyDObp70KKU3YgaYiokhyNkYBoDC5vJqM6s",
  authDomain: "o2i-tri.firebaseapp.com",
  projectId: "o2i-tri",
  storageBucket: "o2i-tri.appspot.com",
  messagingSenderId: "69475241331",
  appId: "1:69475241331:web:a5e60d5ca85682a402c906"
};


export const CLIENT_ID = '69475241331-qog3ditpp443kb8s4q04ekaoks94fmui.apps.googleusercontent.com';
export const CLIENT_SECRET = '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';
export const API_KEY = 'AIzaSyAgrSpZbAuIm6LgQO2haz25yolWeybeW0A';
export const SCOPES = 'https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/drive';  

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore();

export default function App () {
  const {user} = useContext(AuthContext);

  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
     process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  }, []);

  return (
    <Router>
      <Navbar/>
        <Switch>
          <Route exact path="/" >
            <Home/>
          </Route>
          {
            user?.admin?
          <Route path="/admin" >
            <Admin/>
          </Route>
            :
          undefined
          }
          {
            user?.admin?
          <Route path="/create" >
            <CreateDoc/>
          </Route>
            :
          undefined
          }
          {
            user?.admin?
          <Route path="/editdoc" >
            <EditDoc/>
          </Route>
            :
          undefined
          }
          {
            user?.admin?
          <Route path="/statistics" >
            <Statistics/>
          </Route>
            :
          undefined
          }
          <Route path="/all" >
            <SeeAll/>
          </Route>
          {user?.admin?
            <Route path="/formupgrade">
              <FormUpgrade/>
            </Route>
          :
          undefined
          }
          <Route path="/results" >
            <Results/>
          </Route>
          <Route path="/search" >
            <Search/>
          </Route>
          <Route path="/upload" >
              {
                user? <Upload/>:<RequireAuth/>
              }
          </Route>
          <Route exact path="/register" >
            <Register/>
          </Route>
          <Route exact path="/login" >
            <Login/>
          </Route>
          <Route path="/">
            <Login/>
          </Route>
        </Switch>    
      <Footer/>
    </Router>
  )
};