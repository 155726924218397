import { collection, getDocs, orderBy, query } from "@firebase/firestore";
import React, {useContext, useEffect, useState} from "react";
import { db } from "../App";
import Recommend from "../components/Recommend";
import UploadParams from "../assets/UploadParams";
import * as XLSX from "xlsx";
import { UserContext } from "../context/UserContext";



const SeeAll = () => {
    const {language} = useContext(UserContext);

    const [validatedDocs, setValidatedDocs] = useState([]);
    const [sortedDocs, setSortedDocs] = useState({})
    const [loading, setLoading] = useState(true);
    
    const allCategories = ['SD', 'CD', 'OI-R', 'OI-TS', 'PR', 'OI-G', 'CR-PV', 'PA', 'SP-OI'];
    const getValidatedDocs = async() => {
        setLoading(true);
        let validatedDocsArr = [];
        const q = query(collection(db, "resources/documents/validated"), orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        validatedDocsArr.push({...doc.data(), id: doc.id})
        });
        setValidatedDocs(validatedDocsArr);
        setLoading(false);
    };

    const cleanDocs = (docs) => {
        const cleanedDocuments = docs.map(docObj => {
            const lang ={
                docUrl:language=== 'fr'? 'Url de telechargement':'Download url',
                title:language==='fr'?'Titre':'Title',
                country:language==='fr'?'Pays':'Country',
                organisation:language==='fr'?'Organisation':'Organisation',
                period:language==='fr'?'Période':'Period',
                domain:language==='fr'?'Domaine':'Domain',
                OIType:language==='fr'?"Type d'OI":'OI Type',
                reportType:language==='fr'?'Type de rapport':'Report type',
                concernedTitles:language==='fr'?'Titres Concernes':'Concerned Titles',
                editor:language==='fr'?'Editeur':'Editor',
                journal:language==='fr'?'Journal':'Journal',
                fromValidityPeriod:language==='fr'?'Valide du':'Valid from',
                toValidityPeriod:language==='fr'?"Jusqu'au":'Valid until',
                publicationDate:language==='fr'?'Date de publication':'Publication Date',
            };


            let cleanDoc = {};
            cleanDoc[lang.docUrl] = docObj.docUrl;
            cleanDoc[lang.title] = docObj.title;
            cleanDoc[lang.country] = docObj.country;
            cleanDoc[lang.organisation] = docObj.organisation;
            cleanDoc[lang.period] = docObj.period;
            if (docObj.domain) cleanDoc[lang.domain] = UploadParams[language].domain.values.filter(item => item.value == docObj.domain)[0].label;
            if (docObj.OIType) cleanDoc[lang.OIType] = UploadParams[language].OIType.values.filter(item => item.value == docObj.OIType)[0].label;
            if (docObj.reportType) cleanDoc[lang.reportType] = UploadParams[language].reportType.values[docObj.category].filter(item => item.value == docObj.reportType)[0].label;
            if (docObj.concernedTitles) cleanDoc[lang.concernedTitles] = docObj.concernedTitles;
            if (docObj.editor) cleanDoc[lang.editor] = docObj.editor;
            if (docObj.journal) docObj[lang.journal] = docObj.journal;
            if (docObj.fromValidityPeriod) cleanDoc[lang.fromValidityPeriod] = docObj.fromValidityPeriod;
            if (docObj.toValidityPeriod) cleanDoc[lang.toValidityPeriod] = docObj.toValidityPeriod;
            if (docObj.toValidityPeriod) cleanDoc[lang.publicationDate] = docObj.publicationDate;
            
            return cleanDoc; //return the cleaned version of the document after each iteration
        })
        return cleanedDocuments; //Return an array containing the cleaned documents
    };

    const downloadExcel =(data) => {
        console.log("Documents: ", validatedDocs.filter(doc => doc.category ==='CD'))
        const workbook = XLSX.utils.book_new();
        let i =1;
        allCategories.forEach(category => {
            const categoryDocs = validatedDocs.filter(doc => doc.category === category);
            const categoryLabel = UploadParams[language].category.filter(item => item.value === category)[0].label
            // console.log("Category label: ", categoryLabel);
            const worksheet = XLSX.utils.json_to_sheet(cleanDocs(categoryDocs));
            XLSX.utils.book_append_sheet(workbook, worksheet, categoryLabel.split('/').join('|').slice(0, 31));
            i+=1;
        })
        XLSX.writeFile(workbook, "O2I-TRI_"+new Date() +".xlsx");
    };

    
    useEffect(() => {
        let sortDocsObj = {};
        allCategories.forEach(category => {
            const categoryDocs = validatedDocs.filter(doc => doc.category === category);
            const categoryLabel = UploadParams[language].category.filter(item => item.value === category)[0].label
            sortDocsObj[categoryLabel] = categoryDocs;
        });
        setSortedDocs(sortDocsObj);
        // console.log("Sorted documents: ", sortDocsObj);
    }, [validatedDocs]);

    useEffect(() => {
        getValidatedDocs();
    }, []);
    
    return (
        <>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <h1 style={{margin:20}}>
                    {language==='fr'?'Tous les Documents':'All documents'}
                </h1>
                {/* <span>
                    {language =='fr'?'Vous pouvez obtenir une image excel des documents en cliquant'
                    :'You can get an excel image of these documents by clicking'}
                    
                    </span>
                    <span style={{color:'blueviolet', cursor:'pointer', padding:3}} onClick={downloadExcel}>
                    {language==='fr'?' Télécharger en excel':' Download as excel'}
                </span>*/}
            </div>
            {!validatedDocs.length? <div style={{height:'10em'}}></div>:null}
            <Recommend loading={loading} documents={validatedDocs} />
        </>
    )
}

export default SeeAll;