import React, { useContext, useState } from "react";
import styled from "styled-components";
import 'bootstrap/dist/css/bootstrap.min.css';
import {MdAlternateEmail} from 'react-icons/md'
import {AiOutlinePhone, AiOutlineEyeInvisible, AiOutlineEye} from 'react-icons/ai'
import { Link, useHistory } from "react-router-dom";
import {createUserWithEmailAndPassword} from 'firebase/auth';
import { AuthContext } from "../context/AuthContext";
import { doc, setDoc } from "@firebase/firestore";
import { db } from "../App";
import { UserContext } from "../context/UserContext";

export default function Register () {
  const [phoneNum, setPhoneNum] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [firstAttepmpt, setFirstAttempt] = useState(true);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  const {auth} = useContext(AuthContext);
  const {language} = useContext(UserContext);
  const history = useHistory();
  
  const phoneCond = phoneNum.length > 8 && phoneNum.length <= 16;
  const emailCond = email.includes('@')
    && email.includes('.')
    && email.trim('@', '.') === email
    && !email.includes('@.', '.@')
    && email.length >=5
    && !email.includes(['/'])
    ; 
  const passwordCond = password.length >=8;
  const confirmPasswordCond = password === confirmPassword;
  
  const onRegister = async () => {
    if (apiError) {
      setApiError(null);
    }
    if (phoneCond && emailCond && passwordCond && confirmPasswordCond) {
      setLoading(true);
      try {
        const resultAuth = await createUserWithEmailAndPassword(auth, email, password);
        const currentUserId = resultAuth.user.uid;
        console.log(currentUserId);
        await setDoc(doc(db, 'users',currentUserId) , {registeredOn: new Date(), email, phone:phoneNum});
        
        console.log('Account created successfully !')
        localStorage.removeItem('loginSkip');
        history.push({
          pathname:"/",
          state:{type:'register'}}
        );
      } catch (error) {
        if (error.code == "auth/network-request-failed") {
          setApiError(language==='fr'?'Problème de connexion':'Network error');
        } else if (error.code == "auth/too-many-requests") {
            setApiError(language==='fr'?"Trop de tentatives. Veuillez réessayer ultérieurement.":'Too many trials. Please come back later');
        } else if (error.code == "auth/wrong-password") {
            setApiError(language==='fr'?"Le mot de passe est incorrect":'The password is incorrect');
        } else if (error.code == "auth/user-disabled") {
            setApiError(language==='fr'?"Cet utilisateur est suspendu":'The account have been suspended');
        } else if (error.code == "auth/user-not-found") {
            setApiError(language==='fr'?"Ce compte utilisateur n'existe pas":"This user doesn't exist");
        } else if (error.code == "auth/email-already-exists") {
          setApiError(language==='fr'?"Cette addresse email a déjà un compte":"This email address already have an account");
        } else {
          setApiError(language==='fr'?"Une erreur est survenue.":'An error occured.')
        }
        console.log("error during registration: ", error.code)
      }
      setLoading(false)
    } else {
      if(firstAttepmpt) setFirstAttempt(false);
    }
  }
  return (
    <Container>

      <div className="loginLeft">
        <h2>O2I - TRI</h2>
        {language==='fr' ? 'Creez un compte sur la plateforme !' :'Create an account on the platform'}
      </div>
      <div className="loginRight" style={{alignItems:'center', justifyContent:'center'}}>
        <div className="loginBox shadow-sm">
          <div style={{display:'flex', flex:1.1, flexDirection:'column', justifyContent:'space-around'}}>
            <div>
              <AiOutlinePhone color="#0275d8" size={22} style={{position:'absolute', marginTop:14, marginLeft:6}}/>
              <input
                // type="number"
                className="form-control shadow-none"
                value={phoneNum}
                onChange={(e) => setPhoneNum('+' + e.target.value.replace(/[^0-9]/g, ''))}
                placeholder={language == 'fr'?"numero de telephone (international)":"phone number(international)"}
                // onkeyup="this.value=this.value.replace(/[^a-zA-Z0-9]/g, '')"
                />
                {!(firstAttepmpt || phoneCond)? <span className="error" >
                    {language==='fr'? 'Le numero de telephone est incorrect':'The phone number is not correct'}</span> : null}
            </div>
            <div>
              <MdAlternateEmail color="#0275d8" size={22} style={{position:'absolute', marginTop:14, marginLeft:6}}/>
              <input
                onChange={(e) => setEmail(e.target.value)}
                className="form-control shadow-none"
                placeholder={language =='fr'?"addresse email":'email address'} 
              />
              {!(firstAttepmpt || emailCond)? <span className="error" >
                {language==='fr'? "L'addresse email est incorrecte":'The email address is incorrect'}</span> : null}
            </div>
            <div>
              <button className="toggleButton"
                onClick={() => setShowPassword(!showPassword)}
                style={{position:'absolute', marginTop:12, marginLeft:6}}
              >
                
                {showPassword ?
                  <AiOutlineEye color="gray" size={22}/>
                  :
                  <AiOutlineEyeInvisible color="#0275d8" size={22}/>
                }
              </button>
              <input
                onChange={(e) => setPassword(e.target.value)}
                style={{fontSize: showPassword || !password? 'inherit' :20}}
                type={showPassword ? 'text' : 'password'}
                className="form-control shadow-none"
                placeholder={language == 'fr'? "mot de passe":'password'}
                />
                {!(firstAttepmpt || passwordCond)? <span className="error" >
                  {language==='fr'?'Le mot de passe doit avoir au moins 8 characteres':'The password must have at least 8 characters'}
                </span> : null}
            </div>
            <div>
              <button
                className="toggleButton"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                style={{position:'absolute', marginTop:12, marginLeft:6}}
              >

                {showConfirmPassword ?
                  <AiOutlineEye color="gray" size={22}/>
                  :
                  <AiOutlineEyeInvisible color="#0275d8" size={22}/>
                }
              </button>
              <input
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={{fontSize: showConfirmPassword || !confirmPassword? 'inherit' :20}}
                type={showConfirmPassword ? 'text' : 'password'}
                className="form-control shadow-none"
                placeholder={language==='fr'? "confirmez le mot de passe":'confirm your password'}
                />
              {!(firstAttepmpt || confirmPasswordCond) ?<span className="error" >
                {language==='fr'?'Les mots de passes entres ne correspondent pas.':"The passwords doesn't correspond"}
              </span> : null}
                {apiError? <span className="error" >{apiError}</span> : null}
            </div>
          </div>
          <div style={{display:'flex', flex:1, flexDirection:'column', marginTop:20}}>
            <button disabled={loading} onClick={onRegister} style={{margin:10}} className="btn btn-primary shadow-none">
              {language==='fr'?'Créer un compte':'Create an account'}
            </button>
            {loading?<div style={{color:'#1C7D2D', height:25, width:25, marginTop:15, alignSelf:'center'}} class="spinner-border"/>:null}
            <span style={{fontSize:15, marginBottom:-4, marginTop:10, marginLeft:25, color:'gray'}}>
              {language==='fr'?'déjà un compte?':'already have an account?'}
            </span>
            <Link aria-disabled={loading} to={{pathname:"/login", state:{loginPersist:true}}} style={{margin:10}} className="btn btn-success shadow-none">
              {language =='fr'?'Se connecter' :'Connect'}
            </Link>
          </div>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction:row;
  /* border: 2px solid blue; */
  background-color: #E5F6DF;
  align-items:center;
  justify-content: space-between;
  padding: 20px;
  padding-left: 70px;
  padding-right: 70px;
  margin-top:20px;
  margin-bottom:50px;
  border-radius:10px;

  .loginLeft {
    font-size:27px;
    font-weight:450;
    /* border:2px solid purple; */
  }

  .loginBox {
    padding:20px;
    background-color:white;
    /* height:450px; */
    width:350px;
    /* border:2px solid red; */
    border-radius: 20px;

    .form-control {
      height: 40px;
      border-radius: 10px;
      padding-left: 32px;
      letter-spacing: 0.7px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .error {
      font-size: 14px;
      color:#e62b2b;
    }
    input[type='number'] {
    -moz-appearance:textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .toggleButton {
    background: none;
    color: inherit;
    border: none;
    padding: 3;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    .loginBox {
      /* height: 400px; */
      width: 310px;
    }    
  }
  @media screen and (max-width: 720px) {
    flex-direction: column;
    .loginLeft {
      font-size:19px;
      width: 260px;
      margin-bottom:20px;
    }
    h2 {
      font-size: 24px;
    }
    .loginBox {
      /* height: 400px; */
      width: 315px;
      background-color: transparent;
    }
    .form-control {
      margin-bottom:10px
    }
    
  }
`