import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import {collection, setDoc, getDocs, doc, query} from 'firebase/firestore';
import { db } from '../App';
import { UserContext } from '../context/UserContext';
import { AuthContext } from '../context/AuthContext';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';

const CustomInput = ({type, placeholder, size, value, isDisabled, onChange, label}) => {
    const [focused, setFocused] = useState(false);
    const {language} = useContext(UserContext);
    return(
        <div style={{marginBottom:25, display:isDisabled ? 'none' : undefined}}>
            <div className={"col-md-1"}></div>
            <div className={"col-md-" + size}>
                {type === 'file'? 
                <label aria-disabled={isDisabled} className="btn btn-primary" style={{borderRadius:10}}>
                    {language==='fr'?'Choisir un fichier':'Choose a file'}
                <input
                    accept=".pdf,.doc,.docx,.txt"
                    hidden
                    disabled={isDisabled}
                    onChange={onChange}
                    placeholder={placeholder}
                    style={{borderRadius:10, height:38, borderColor:focused ? 'blue' : '#cccccc', borderWidth: focused ? 2:1, color:isDisabled? '#677' :'black'}}
                    type={type ? type : "text"}
                    
                    className="form-control shadow-none"
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    value={value}
                    />
                    </label>
                    :
                    <label style={{display:'flex', flex:1, flexDirection:'column'}}>
                        {label ? 
                        <span style={{marginTop:-13, marginBottom:3, marginLeft:6,fontSize:16.5}}>
                            {label}
                        </span>
                        : null
                        }
                        <input
                        disabled={isDisabled}
                        onChange={onChange}
                        placeholder={placeholder}
                        style={{borderRadius:10, height:38, borderColor:focused ? 'blue' : '#cccccc', borderWidth: focused ? 2:1, color:isDisabled? '#677' :'black'}}
                        type={type ? type : "text"}
                        id="form12"
                        className="form-control shadow-none"
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        value={value}
                        />
                    </label>
                    }
            </div>
            <div className={"col-md-" + 11-size}></div>
            
        </div>
    )
}

function customTheme (theme) {
    return {
        ...theme,
        borderRadius:10,
        borderWidth:5,
        colors:{
            ...theme.colors,
            primary25: '#cccccc',
            primary:'green'
        }
    }
}

export const CustomSelect = ({placeholder, size, options, isDisabled, value, setState, noOptionsMessage}) => {
    return(
    <div style={{marginBottom:25, display: isDisabled ? 'none' : undefined}}>
        <div className={"col-md-1"}></div>
        <div className={"col-md-" + size}>
            <Select
                isDisabled={isDisabled}
                noOptionsMessage={noOptionsMessage}
                options={ options }
                placeholder={placeholder}
                theme={customTheme}
                onChange={setState}
                value={value}
                />
        </div>
        <div className={"col-md-"+ 11-size}></div>
        
    </div>)
};


const EditDoc = () => {
  
    const {language} = useContext(UserContext);
    const {user} = useContext(AuthContext);

    const location = useLocation()
    const document = location.state?.document ||{};
    const superset = location.state.superset;

    const history = useHistory();

    const [formParams, setFormParams] = useState(null);

    const countryVal = formParams? formParams[language].country.filter(country => country.value=== document.country)[0]:null;
    const organisationVal = formParams? formParams[language].organisation[document.country].filter(org => org.value === document.organisation)[0]:null;
    const categoryVal = formParams? formParams[language].category.filter(item => item.value === document.category)[0]:null;
    const OITypeVal = formParams? formParams[language].OIType.values.filter(obj => obj.value === document.OIType)[0]:null;
    console.log('form params report type',formParams?.[language]?.reportType.values);
    console.log('Document category: ', document.category);
    const reportTypeVal = formParams? formParams[language].reportType.values[document.category].filter(obj => obj.value === document.reportType)[0]:null;
    const periodVal = {label:document.period, value:document.period};
    const domainVal = formParams? formParams[language].domain.values.filter(obj => obj.value === document.domain)[0]:null;

    const [shouldReinit, setShouldReinit] = useState(true);

    const [organisation, setOrganisation] = useState('');
    const [period, setPeriod] = useState('');
    const [category, setCategory] = useState('');
    const [domain, setDomain] = useState('');
    const [OIType, setOIType] = useState('');
    const [reportType, setReportType] = useState('');
    const [country, setCountry] = useState(null);
    const [docTitle, setDocTitle] = useState(document.title||'');
    const [concernedTitle, setConcernedTitle] = useState(document.concernedTitles||'');
    const [docEditor, setDocEditor] = useState(document.editor||'');
    const [journal, setJournal] = useState(document.journal||'');
    const [fromValidityPeriod, setFromValidityPeriod] = useState(document.fromValidityPeriod||'');
    const [toValidityPeriod, setToValidityPeriod] = useState(document.toValidityPeriod||'');
    const [publicationDate, setPublicationDate] = useState(document.publicationDate||'');
    const [loading, setLoading] = useState(false);
    

    async function getFormParams () {
        let uploadParams = {};
        const q = query(collection(db, "uploadparams"));

        const params = await getDocs(q);
        params.forEach(lang => {
            uploadParams[lang.id] = lang.data()
        });
        
        if(Object.keys(uploadParams).length ===0) {
            uploadParams = null;
        }

        setFormParams(uploadParams);
    }
    function isInputAllowed (type) {
        return category && formParams && formParams[language].placeholders[type].allowed.includes(category.value);
    }

    function isSelectAllowed (type) {
        return category && formParams && formParams[language][type].allowed.includes(category.value);
    }
    
    // const onFormSubmit = async () => {
    //     await setDoc(doc(db, 'uploadparams', 'fr'), {...UploadParams.fr});
    //     await setDoc(doc(db, 'uploadparams', 'en'), {...UploadParams.en});
    //     alert('Upload params loaded succesfully');
    // };

    const onFormSubmit = () => {
        const updateDocument = async(category, properties) => {
            if(!loading) {
                setLoading(true);
                console.log('Updating document...');
                await setDoc(doc(db, 'resources', 'documents', superset, document.id), {...properties, category}, {merge:true});
                setLoading(false);
                alert(language==='fr'?"Document mis a jour avec succès":'Document updated successfully');
            }
        }

        const condition = 
            docTitle
            &&country 
            &&organisation
            && period
            && category
            && (!isSelectAllowed('domain') || domain)
            && (!isSelectAllowed('OIType') || OIType)
            && (!isSelectAllowed('reportType') || reportType)
            && (!isInputAllowed('concernedTitles') || concernedTitle)
            && (!isInputAllowed('editor') || docEditor)
            && (!isInputAllowed('journal') || journal)
            && (!isInputAllowed('validityPeriod') || (fromValidityPeriod && toValidityPeriod))
            && (!isInputAllowed('publicationDate') || publicationDate)
            
        if (condition) {
            let docObj = {};
            docObj.title = docTitle;
            docObj.country = country.value;
            docObj.organisation = organisation.value;
            docObj.period = period.value;
            if (isSelectAllowed('domain')) docObj.domain = domain.value;
            if (isSelectAllowed('OIType')) docObj.OIType = OIType.value;
            if (isSelectAllowed('reportType')) docObj.reportType = reportType.value;
            if (isInputAllowed('concernedTitles')) docObj.concernedTitles = concernedTitle;
            if (isInputAllowed('editor')) docObj.editor = docEditor;
            if (isInputAllowed('journal')) docObj.journal = journal;
            if (isInputAllowed('validityPeriod')) docObj.fromValidityPeriod = fromValidityPeriod;
            if (isInputAllowed('validityPeriod')) docObj.toValidityPeriod = toValidityPeriod;
            if (isInputAllowed('publicationDate')) docObj.publicationDate = publicationDate;

            try {
                updateDocument(category.value, docObj);
            } catch (error) {
                setLoading(false);
                console.log('Error while saving the document: ', error)
            }
        } else {
            alert(language==='fr'?'Un ou plusieurs champs sont vides, vérifiez encore.':'One or more fields are empty, please check again');
        }
    }

    const onSeePress = () => {
      if(superset==='editing') {
        window.open("https://docs.google.com/document/d/"+ document.GDocsId+"/edit", "_blank");
      } else {
        window.open(document.docUrl);
      }
    }

    let periodOptions = [];
    for (let i =2010; i< 2035; i++){
        periodOptions.push({label: i.toString(), value:i})
    }

    useEffect(() => {
      if(shouldReinit) {
        setOrganisation('');
      }  
    }, [country]);

    useEffect(() => {
      if(shouldReinit) {
        setDomain('');
        setOIType('');
        setReportType('');
      } else {
        setShouldReinit(true);
      }
    }, [category]);
    
    useEffect(() => {
      setShouldReinit(false);
      
      setOrganisation(organisationVal);
      setPeriod(periodVal);
      setCategory(categoryVal);
      setDomain(domainVal);
      setOIType(OITypeVal);
      setReportType(reportTypeVal);
      setCountry(countryVal);

    }, [formParams]);

    useEffect(() => {
        getFormParams();
    }, [])

    
    // if(!formParams) {
    //     return null;
    // }
    return (
        <div>
            <Header >
                <h1>
                    {language==='fr'?'Voir le document':'See the document'}
                </h1>
            </Header>
            <div className="row">
                <CustomInput
                    onChange={(e) => setDocTitle(e.target.value)}
                    value={docTitle}
                    placeholder={formParams? formParams[language].placeholders.title.value:''}
                    size={4}
                />

                {/* <div style={{marginBottom:25}}>
                    <div className={"col-md-1"}></div>
                    <div className={"col-md-4"}>
                        <Select
                            noOptionsMessage={() =>"Aucune option dispoinble"}
                            options={ formParams[language].country }
                            placeholder={"Pays"}
                            theme={customTheme}
                            onChange={({value}) => {
                                setCountry(value);
                            }}
                            />
                    </div>
                    <div className={"col-md-7"}></div>
                    
                </div> */}
                <CustomSelect
                    placeholder={language==='fr'?"Pays":'Country'}
                    size={4}
                    options={formParams? formParams[language].country:[]}
                    value={country}
                    setState={(value) => {
                        setCountry(value);
                        setOrganisation('');
                    }}
                />
                {country ? (
                    <CustomSelect
                        type="organisation"
                        placeholder="Organisation"
                        size={5}
                        options={formParams? formParams[language].organisation[country.value]:[]}
                        value={organisation}
                        setState={(value) => setOrganisation(value)}
                    />
                ) : (
                    <CustomSelect
                        type="organisation"
                        placeholder="Organisation"
                        size={5}
                        noOptionsMessage={() =>"Veuillez selectionner un pays"}
                        // options={formParams[language].organisation.CMR}
                    />
                )
                    
                }

                <CustomSelect
                    setState={(value) => setPeriod(value)}
                    value={period}
                    placeholder={language==='fr'? 'Période':'Period'}
                    size={4}
                    options={periodOptions}
                />
                <CustomSelect
                    type="category"
                    placeholder={language==='fr'? "Catégorie":'Category'}
                    size={5}
                    options={formParams? formParams[language].category:[]}
                    value={category}
                    setState={(value) => setCategory(value)}
                />
                <CustomSelect
                    type="domain"
                    placeholder={language==='fr'?"Domaine d'intervention":'Intervention domain'}
                    size={5}
                    isDisabled={!isSelectAllowed('domain')}
                    options={formParams? formParams[language].domain.values:[]}
                    value={domain}
                    setState={(value) => setDomain(value)}
                />
                <CustomSelect
                    type="OIType"
                    placeholder={language==='fr'? "Type d'OI":'OI type'}
                    size={5}
                    isDisabled={!isSelectAllowed('OIType')}
                    options={formParams? formParams[language].OIType.values:[]}
                    value={OIType}
                    setState={(value) => setOIType(value)}
                />
                <CustomSelect
                    type="reportType"
                    placeholder={language==='fr'? "Type de rapport":'Report type'}
                    size={5}
                    isDisabled={!(category && category && isSelectAllowed('reportType'))}
                    options={formParams? formParams[language].reportType.values[category?.value]:[]}
                    value={reportType}
                    setState={(value) => setReportType(value)}
                />
                <CustomInput
                    onChange={(e) => setConcernedTitle(e.target.value)}
                    value={concernedTitle}
                    placeholder={formParams? formParams[language].placeholders.concernedTitles.value:''}
                    size={4}
                    isDisabled={!isInputAllowed('concernedTitles')}
                />
                <CustomInput
                    onChange={(e) => setDocEditor(e.target.value)}
                    value={docEditor}
                    placeholder={formParams? formParams[language].placeholders.editor.value:''}
                    size={3}
                    isDisabled={!isInputAllowed('editor')}
                />
                <CustomInput
                    onChange={(e) => setJournal(e.target.value)}
                    value={journal}
                    placeholder={formParams? formParams[language].placeholders.journal.value:''}
                    size={4}
                    isDisabled={!isInputAllowed('journal')}
                />
                <CustomInput
                    type="date"
                    onChange={(e) => setFromValidityPeriod(e.target.value)}
                    value={fromValidityPeriod}
                    label={'Valide du'}
                    size={4}
                    isDisabled={!isInputAllowed('validityPeriod')}
                />
                <CustomInput
                    type="date"
                    onChange={(e) => setToValidityPeriod(e.target.value)}
                    value={toValidityPeriod}
                    label={'au'}
                    size={4}
                    isDisabled={!isInputAllowed('validityPeriod')}
                />
                <CustomInput
                    type='date'
                    onChange={(e) => setPublicationDate(e.target.value)}
                    value={publicationDate}
                    placeholder={formParams? formParams[language].placeholders.publicationDate.value:''}
                    label={language=='fr'?'Date de publication':'Publication date'}
                    size={4}
                    isDisabled={!isInputAllowed('publicationDate')}
                />
            
                <div style={{marginBottom:25}}>
                    <div className={"col-md-1"}></div>
                    <div className={"col-md-4"} style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <button disabled={loading} onClick={onFormSubmit} className="btn btn-success shadow-none"
                          style={{width:140, borderRadius:10, marginTop:15}}>
                            {language == 'fr'?'Appliquer':'Apply'}
                        </button>
                        <a onClick={onSeePress} disabled={loading} className="btn btn-primary shadow-none"
                          style={{width:160, marginLeft:30, borderRadius:10, marginTop:15}}>
                            {language == 'fr'?'Voir le document':'See the document'}
                        </a>
                        {loading?<div style={{color:'#1C7D2D', height:25, width:25, marginTop:15, marginLeft:30}} class="spinner-border"/>:null}
                    </div>
                    <div className={"col-md-7"}></div>
                    
                </div>
            </div>
        </div>
    );
} 

export default EditDoc;

const Header = styled.div`
    margin-bottom:40px;
    margin-top:20px;
    display:flex;
    flex-direction:row;
    align-items:center;
    .formSettings {
        margin-left:50px;
        border-radius:50%;
        padding:3px;
        transition: 0.1s ease-in-out;
        &:hover{
        background-color:rgba(0, 0, 0, 0.2); 
        }
    }
`