import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AuthProvider from './context/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
import UserContextProvider from './context/UserContext';

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider
      clientId="69475241331-qog3ditpp443kb8s4q04ekaoks94fmui.apps.googleusercontent.com"
    >
      <AuthProvider>
        <UserContextProvider>
            <App />
        </UserContextProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

