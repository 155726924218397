import React, { useState, useEffect, useContext } from "react";
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import UploadParams from "../assets/UploadParams";
import AnimateHeight from "react-animate-height";
import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io';
import {ImStatsBars} from 'react-icons/im';
import { db, SCOPES } from "../App";
import { useHistory } from "react-router";
import { getDocs, query, setDoc, collection, orderBy, doc, addDoc, deleteDoc } from "@firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytes, deleteObject } from "@firebase/storage";
import Modal from "react-modal";
import { AuthContext } from "../context/AuthContext";
import { useGoogleLogin } from "@react-oauth/google";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import texts from "../assets/texts";


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius:10
    },
};

const CustomModal = ({modalIsOpen, onConfirm, closeModal,editing, waiting}) => {
    let subtitle;
    const {language} = useContext(UserContext);
    function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = waiting? '#ffbb33':'#f00';
    }
    
    const frmodalDesc = !editing ? 'Si vous faites ça le document sera retire de la base de donnees et ne pourra plus etre restaure'
                                :'Si vous faites ça le document sera retire de la base de donnees. Mais il sera toujours disponible dans Google Docs';   
    const enmodalDesc = !editing?'If you confirm, the document will be removed from the database and cannot not be restored'
                                :'If you continue, the document will be removed from the database. But it will still exists inside your Google Documents'
    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
        >
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{language==='fr'? 'Etes vous sur?':'Are you sure?'}</h2>
            <span style={{display:'flex', flexWrap:'wrap',maxWidth:300, padding:10}}>{language==='fr'? frmodalDesc:enmodalDesc}</span>
            <div>
                {waiting ?
                    <button className="btn btn-warning shadow-none" style={{marginRight:20}} onClick={onConfirm}>{language==='fr'? 'Confirmer':'Confirm'}</button>
                    : 
                    <button className="btn btn-danger shadow-none" style={{marginRight:20}} onClick={onConfirm}>{language==='fr'? 'Supprimer':'Delete'}</button>
                }
                <button className="btn btn-secondary shadow-none" onClick={closeModal}>{language==='fr'? 'Annuler':'Cancel'}</button>
            </div>
        </Modal>
        )
    }

const Card = ({title, subTitle, number}) => {
    return (
        <div className="card shadow-sm">
            <span className="title" >{title}</span>
            <span className="subTitle" >
                <span style={{fontWeight:530, color:'green'}}>
                    {number + ' '}
                </span>
                {subTitle}
            </span>
        </div>
    )
}

const DropDown = ({type, promoteAdmin, removeAdmin, superadmin}) => {
    const {language} = useContext(UserContext);
    console.log('Super admin: ', superadmin);
    return (
        <div className="dropdown">
            <a href="#" style={{fontSize:18}} className="dropdown-toggle" data-bs-toggle="dropdown">···</a>
            <div className="dropdown-menu">
                {
                    type=='admin'?
                        (<span onClick={removeAdmin} href="#" className={superadmin?"dropdown-item disabled":"dropdown-item"}>{language==='fr'? "Retirer les droits d'admin":'Remove admin rights'}</span>)
                        :
                        (<span onClick={promoteAdmin} href="#" className="dropdown-item">{language==='fr'? 'Promouvoir admin':'Promote admin'}</span>)
                }
                {/* <span href="#" className="dropdown-item">Désactiver l'utilisateur</span> */}
            </div>
        </div>
    );
}

const UserTemplate = ({user, type, promoteAdmin, removeAdmin, aboutAdmin}) => {
    const {language} = useContext(UserContext);

    const getReadableDate = (date) => {
        const months = {
            en:['January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September','October', 'November', 'December'],
            fr:['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin',
                'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
            };
        const readableDate = date.getDate() + ' ' + months[language][date.getMonth()]+' '+date.getFullYear();
        return readableDate;
    };
    
    return (
        <div
        className="shadow-sm"
        style={{height:50, backgroundColor:'#f0f2f5', width:'100%', borderRadius:5,marginBottom:10, paddingLeft:10, paddingRight:10, paddingTop:3}}
            >
            <div style={{display:'flex', flexDirection:'row',alignItems:'center', justifyContent:'space-between'}}>
                {user.email}
                <DropDown promoteAdmin={promoteAdmin} removeAdmin={removeAdmin} superadmin={aboutAdmin?.superadmin} type={type}/>
            </div>
            <div style={{display:'flex', justifyContent:'space-between', fontSize:14}}>
                <span>
                    {user.phone}
                </span>
                <span>
                    {
                        type==='admin'?
                            language==='fr'?'Admin depuis le ':'Admin since the ' + getReadableDate(aboutAdmin.isAdminSince.toDate())
                        :language==='fr' ? 'Enregistré depluis le ' :'Registered since the '+ getReadableDate(user.registeredOn.toDate())
                    } 
                </span>
            </div>      
        </div>
    )
}


const DocTemplate = ({document, editing, waiting, clearDoc, gToken, setGToken}) => {
    const {language} = useContext(UserContext);

    const [height, setHeight] = useState(80);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formParams, setFormParams] = useState(null);
    const storage = getStorage();
    const history = useHistory();


    const getReadableDate = (date) => {
        const months = {
            en:['January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September','October', 'November', 'December'],
            fr:['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin',
                'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
            };
        const readableDate = date.getDate() + ' ' + months[language][date.getMonth()]+' '+date.getFullYear();
        return readableDate;
    };

    const isExpanded = height === 'auto';
    const toggle = () => {
      if (height === 80) {
          setHeight('auto');
      } else {
          setHeight(80);
      }
    };
    const dropProperties = ['domain', 'OIType', 'reportType', 'period', 'fromValidityPeriod', 'toValidityPeriod'];
    const inputProperties = ['concernedTitles', 'editor', 'journal', 'validityPeriod', 'publicationDate'];
    
    async function getFormParams () {
        let uploadParams = {};
        const q = query(collection(db, "uploadparams"));

        const params = await getDocs(q);
        params.forEach(lang => {
            uploadParams[lang.id] = lang.data()
        });
        
        if(Object.keys(uploadParams).length ===0) {
            uploadParams = null;
        }

        setFormParams(uploadParams);
    };

    const onDelete = () => {
        setShowModal(true)
    }
    const onInvalidation = () => {
        setShowModal(true)
    };

    const onSeeMore = () => {
        history.push('/editdoc', {
            document,
            superset:editing?'editing' : waiting?'waiting' :'validated',
        })
    }
    const onValidation= async () => {
        setLoading(true);
        let docWithoutId = document;
        const docId = document.id;
        delete docWithoutId.id;
         
        await addDoc(collection(db, 'resources', 'documents', 'validated'), docWithoutId);
        await deleteDoc(doc(db, 'resources', 'documents', 'waiting', docId));
       
        setLoading(false);
        clearDoc(docId)
    }

    const onModalConfirm = async() => {
        setLoading(true);
        const fileRef = ref(getStorage(), document.docUrl);
        try {
            const docId = document.id;
            if (editing) {
                await deleteDoc(doc(db, 'resources', 'documents', 'editing', docId))
            } else {
                await Promise.allSettled([
                    deleteDoc(doc(db, 'resources', 'documents', waiting?'waiting':'validated', docId)),
                    deleteObject(fileRef).then(() => console.log('File deleted successfully')),
                ]);
            }
            setLoading(false);

            clearDoc(docId);
        } catch (error) {
            setLoading(false);
            console.log('Error during document deletion: ', error);
        }

    };

    const onGDocOpen = () => {
        window.open("https://docs.google.com/document/d/"+ document.GDocsId+"/edit", "_blank");
    };

    
    const googleLogin = useGoogleLogin({
        scope:SCOPES,
        flow:'implicit',
        onSuccess: async tokenRes => {
            console.log('Token response: ', tokenRes);
            const GDoc = await (fetchDoc(tokenRes.access_token));
            // await setGToken(tokenRes.access_token);
            deployDoc(GDoc);

        }
    });
    
    const deployDoc = async (GDoc) => {
        const blobDoc = await GDoc.blob();
        const fileName = document.fileName;
        const storageRef = ref(storage, `documents/${fileName}`);
        await uploadBytes(storageRef, blobDoc)
        const downloadUrl = await getDownloadURL(storageRef);
        console.log('Document properties', document)
        await addDoc(collection(db, 'resources','documents', 'validated'), {...document, docUrl:downloadUrl, deployedAt: new Date()}); 
        alert(language==='fr'?'Publié avec succès !':"Published successfully !");
        if(loading) {
            setLoading(false);
        }
    };

    const fetchDoc = async(token) => {
        const GDoc = await fetch("https://www.googleapis.com/drive/v3/files/"+document.GDocsId+"/export?mimeType=application/pdf",
            {
                method:'GET',
                headers: new Headers({'Authorization':'Bearer '+token})
            },
        );
        return GDoc;
    };
    
    const onGDocDeploy = async() => {
        setLoading(true);
        console.log("Current token:", gToken);
        const GDoc = await fetchDoc(gToken);

        console.log("Google docs deployment result: ", GDoc);
        if (GDoc.type === 'cors') {
            if(GDoc.status === 401) {
                googleLogin();
                // console.log("Didnt waited for the user to login");
                // deployDoc();
            } else {
                setLoading(false);
                alert(language==='fr'?"Vous n'avez pas les droits pour publier ce document":"You dont have the rights to deploy this document");
            }
        } else if (GDoc.type ==='error') {
            setLoading(false);
            alert(language==='fr'?'Une erreur est survenue':"an error occured");
        } else {
            deployDoc(GDoc);
        }

    };

   useEffect(() => {
    getFormParams();
   }, [])
    return (
        
        <AnimateHeight
        style={{backgroundColor:'#f0f2f5', width:'98%', borderRadius:5,marginBottom:10, padding:'7px 15px 5px 15px'}}
        id='example-panel'
        duration={ 500 }
        height={ height } // see props documentation below
        className="shadow-sm container"
        >   
            <CustomModal
                waiting={waiting}
                editing={editing}
                modalIsOpen={showModal}
                closeModal={() => setShowModal(false)}
                onConfirm={() => {setShowModal(false); onModalConfirm()}}
                doc = {document}
                />
            <div style={{width:'100%', display:'flex', justifyContent:'flex-end'}}>
                <div
                    className="mobile-see-more"
                    onClick={toggle}
                    style={{display:'flex', height:32, width:32, borderRadius:'50%', alignItems:'center', justifyContent:'center'}}>
                    {isExpanded ?
                        <IoIosArrowDropup size={30}/>
                        :
                        <IoIosArrowDropdown size={30}/>
                    }
                </div>
            </div>
            <div className="row">
                <span>
                    {language =='fr'? 'Titre:':'Title:'} {document.title}
                </span>
                <span>
                {language =='fr'? 'Pays:':'Country:'} {formParams? formParams[language].country.filter(country => country.value=== document.country)[0].label:null}
                </span>
            </div>
            <div className="row">
                <span>
                    Organisation: {formParams?formParams[language].organisation[document.country].filter(org => org.value === document.organisation)[0].label:null}
                </span>
                <span>
                {language =='fr'? 'Catégorie:':'Category:'} {UploadParams[language].category.filter(item => item.value === document.category)[0].label}
                </span>
            </div>
            <div className="row">
                <span>
                {language =='fr'? 'En attente depuis le: ':'Waiting since the: '}{getReadableDate(document.createdAt.toDate())}
                </span>
                <span>
                {language =='fr'? ' Document chargé par ':'Document loaded by '}{document.loaderEmail}
                </span>
            </div>
            {/* <div> */}
                {/* <span */}
                    {/* style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', padding:10, paddingBottom:0, paddingTop:0, color:'green'}}> */}
                    {/* Voir plus */}
                    {/* <p */}
                    {/* aria-expanded={ isExpanded } */}
                    {/* aria-controls='example-panel' */}
                    {/* onClick={ toggle } */}
                    {/* style={{cursor:'pointer'}} */}
                    {/* className="see-more" */}
                    {/* > */}
                        {/* {isExpanded ? language==='fr'? 'Voir moins':'See less' : language==='fr'? 'Voir plus':'See more'} */}
                    {/* </p> */}
                {/* </span> */}
            {/* </div> */}
            <div className="row">
                <span style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: 10, flex:2 }}>
                {dropProperties.map(prop => {
                  if (document[prop]) {
                    if (prop === 'reportType') {
                      return <span>• {UploadParams[language].reportType.values[document.category].filter(obj => obj.value === document.reportType)[0].label}</span>
                    } else if(prop==='period') {
                      return <span>• {language==='fr'? 'Période: ':'Period: '} {document['period']}</span>
                    } else if(prop === 'fromValidityPeriod') {
                      return <span>• {language==='fr'? 'Valide du':'Valid from the'} {document['fromValidityPeriod']} {language==='fr'? 'au':'to the'} {document['toValidityPeriod']}</span>
                    } else if(prop === 'toValidityPeriod') {
                      return null;
                    } else {
                      return <span>• {UploadParams[language][prop].values.filter(obj => obj.value === document[prop])[0].label}</span>
                    }
                  } else {
                    return null;
                  }
                })}
                {inputProperties.map(prop => {
                  if (document[prop]) {
                    if (prop === 'publicationDate') {
                        return <span>• {language==='fr'? 'Publié le ':'Published on the '} {getReadableDate((new Date(document['publicationDate'])))}</span>
                    } else {
                        return <span key={prop} >• {document[prop]}</span>
                    }
                  } else {
                    return null;
                  }
                })}
                </span>
                <span style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', gap:12, marginTop:5}}>
                        <div>
                            <button disabled={loading} onClick={onSeeMore} className="doc-toggle btn btn-primary shadow-none">{language==='fr'? 'Voir plus':'See more'}</button>
                        </div>
                    {editing? (
                    <>
                        <div>
                            <button disabled={loading} onClick={onGDocDeploy} className="doc-toggle btn btn-success shadow-none">{language==='fr'? 'Publier':'Publish'}</button>
                        </div>
                        {/* <div>
                            <button disabled={loading} onClick={onGDocOpen} className="doc-toggle btn btn-success shadow-none">{language==='fr'? 'Ouvrir':'Open'}</button>
                        </div> */}
                    </>
                    ):
                    null}

                    {waiting? (
                    <>
                    <div>
                        <button disabled={loading} onClick={onInvalidation} className="doc-toggle btn btn-warning shadow-none">{language==='fr'? 'Invalider':'Invalidate'}</button>
                    </div>
                    <div>
                        <button disabled={loading} onClick={onValidation} className="doc-toggle btn btn-success shadow-none">{language==='fr'? 'Valider':'Validate'}</button>
                    </div>
                    </>) :
                    <div>
                        <button disabled={loading} onClick={onDelete} className="doc-toggle btn btn-danger shadow-none">{language==='fr'? 'Supprimer':'Delete'}</button>
                    </div>
                    }
                </span>
            </div>
        </AnimateHeight>
        
    );

}


export default function Admin () {
    const {user, gToken, setGToken} = useContext(AuthContext)
    const {language} = useContext(UserContext);

    const [users, setUsers] = useState([]);
    const [editingDocs, setEditingDocs] = useState([]);
    const [waitingDocs, setWaitingDocs] = useState([]);
    const [validatedDocs, setValidatedDocs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [admins, setAdmins] = useState([])
    
    
    const getUsers = async() => {
        let usersArr = [];
        const q = query(collection(db, "users"), orderBy('registeredOn', 'desc'));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        usersArr.push({...doc.data(), id: doc.id})
        });
        setUsers(usersArr);
    };

    const getAdmins = async() => {
        let adminsArr = []
        const q = query(collection(db, "admins"), orderBy('isAdminSince', 'desc'));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        adminsArr.push({...doc.data(), id: doc.id})
        });
        setAdmins(adminsArr);
        console.log('admins: ', adminsArr);
    }

    const getWaitingDocs = async() => {
        let waitingDocsArr = [];
        const q = query(collection(db, "resources/documents/waiting"), orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        waitingDocsArr.push({...doc.data(), id: doc.id})
        // console.log(doc.id, " => ", doc.data());
        });
        setWaitingDocs(waitingDocsArr);
    };

    const getValidatedDocs = async() => {
        let validatedDocsArr = [];
        const q = query(collection(db, "resources/documents/validated"), orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        validatedDocsArr.push({...doc.data(), id: doc.id})
        });
        setValidatedDocs(validatedDocsArr);
    };

    const getEditingDocs = async() => {
        let editingDocsArr = [];
        const q = query(collection(db, "resources/documents/editing"), orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        editingDocsArr.push({...doc.data(), id: doc.id})
        });
        setEditingDocs(editingDocsArr);
    };

    const isAdmin =(id) => {
        return !admins.every(member => member.id != id)
    }

    const promoteAdmin = async(id) => {
        await setDoc(doc(db, 'admins',id) , {admin:true, superadmin:false, isAdminSince: new Date()});
        setAdmins([...admins, users.filter(user => user.id === id )[0]])
    }



    const removeAdmin = async(id) => {
        await deleteDoc(doc(db, 'admins',id));
        setAdmins(admins.filter(member => member.id != id))
    }
    
    const clearWaiting = (id) => {
        console.log('Received id: ', id)
        let newWaiting = waitingDocs;
        newWaiting = waitingDocs.filter(doc => (doc.id != id) && doc.id)
        setWaitingDocs([...newWaiting]);
        console.log('new documents: ', newWaiting)
    }

    const clearValidated = (id) => {
        let newValidated = validatedDocs
        newValidated = validatedDocs.filter(doc => doc.id != id)
        setValidatedDocs([...newValidated]);
    }

    const clearEditing = (id) => {
        let newEditing = editingDocs;
        newEditing = editingDocs.filter(doc => doc.id != id)
        setEditingDocs([...newEditing]);
    }
    
    useEffect(() => {
        async function getData () {
            await Promise.allSettled([  //promise.allsettled resolves after all the nested promises reslove
                getUsers(),             //or reject. So, all promises will execute.
                getAdmins(),
                getWaitingDocs(),
                getValidatedDocs(),
                getEditingDocs(),
            ]);
            setLoading(false);
        }
        getData();
    }, []);
    return (
        <Container>
            <div className="cardsContainer">
                <Card
                    title={texts[language].admin.card1Title}
                    number={loading ? "..." : users.length}
                    subTitle={texts[language].admin.card1Subtitle}
                    />
                <Card
                    title={texts[language].admin.card2Title}
                    number={loading ? "..." : waitingDocs.length}
                    subTitle={texts[language].admin.card2Subtitle}
                    />
                <Card title={texts[language].admin.card3Title}
                    number={loading ? "..." : validatedDocs.length}
                    subTitle={texts[language].admin.card3Subtitle}
                />
                {/* <Card/> */}
            </div>
            <div style={{alignItems:'flex-end', justifyContent:'flex-end', display:'flex', flex:1, marginRight:70}}>
                <Link to="/statistics" className="shadow ps-2 pe-2 pt-1 pb-1 rounded" style={{textDecoration:'none', paddingLeft:10, paddingRight:10}}>
                    <ImStatsBars style={{marginBottom:2}}/>
                    Voir Plus
                </Link>
            </div>
            {/* <button style={{width:200, height:50, backgroundColor:'beige'}} onClick={() => setShowModal(true)} /> */}
            <div className="usersDiv">
                <div className="usersContainer">
                <span className="boxTitle" >{texts[language].admin.usersContainer}</span>
                    <div
                        // className="usersContainer"
                        style={{overflowY:'scroll'}}
                    >
                        {users.filter(user => admins.every(member => member.id != user.id))
                            .map(user => 
                                <UserTemplate
                                    promoteAdmin={() => promoteAdmin(user.id)}
                                    user={user}
                                    key={user.id}
                                />)
                        }  
                    </div>
                </div>
                <div className="usersContainer">
                    <span className="boxTitle" >{texts[language].admin.adminsContainer}</span>
                    <div
                        style={{overflowY:'scroll'}}
                        >
                        {users.filter(user => !admins.every(member => member.id != user.id))
                            .map(user => 
                                <UserTemplate
                                    removeAdmin={() => removeAdmin(user.id)}    
                                    user={user}
                                    key={user.id}
                                    type='admin'
                                    aboutAdmin={admins.filter(admin => admin.id === user.id)[0]}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="docsContainer">
                <span className="boxTitle" >{texts[language].admin.waitingContainer}</span>
                <div
                    style={{overflowY:'scroll'}}
                    >
                    {waitingDocs.map(document => 
                        <DocTemplate
                        key={document.id}
                        waiting={true}
                        document={document}
                        clearDoc={clearWaiting}
                        />
                    )}
                </div>  
            </div>
            <div className="docsContainer">
                <span className="boxTitle" >{texts[language].admin.publicDocs}</span>
                <div
                    style={{overflowY:'scroll'}}
                    >
                    {validatedDocs.map(document => 
                        <DocTemplate
                            key={document.id}
                            waiting={false}
                            document={document}
                            clearDoc={clearValidated}
                            />
                    )}
                </div>  
            </div>
            <div className="docsContainer">
                <span className="boxTitle" >{texts[language].admin.editingDocs}</span>
                <div
                    style={{overflowY:'scroll'}}
                    >
                    {editingDocs.map(document => 
                        <DocTemplate
                            key={document.id}
                            waiting={false}
                            editing={true}
                            document={document}
                            gToken={gToken}
                            clearDoc={clearEditing}
                            setGToken={setGToken}
                            />
                    )}
                </div>  
            </div>
            <div className="docCreateContainer">
                <Link to="/create" className="docCreate">
                {texts[language].admin.createDoc}
                </Link>
            </div>
        </Container>
    )
}

const Container = styled.div`
    /* height: 68vh; */
    border-radius:20px;
    /* background-color: #f0f2f5; */
    background-color: #E5F6DF;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top:40px;
    padding-bottom:40px;
    .cardsContainer {
        display: flex;
        flex-direction: row;
        /* border: 1px solid black; */
        align-items: center;
        justify-content: space-around;
        margin: 30px 15px 0px 15px;

        .card {
            height:140px;
            width:285px;
            background-color:white;
            border-radius:15px; 
            margin-bottom:30px;
            .title {
                font-size:22px;
                font-weight: 550;
                align-self: center;
                margin:10px;
            }
            .subTitle {
                margin: 0px 30px 0px 30px;
            }
        }
    }
    .usersDiv {
        display: flex;
        flex-direction: row;
    }
    .usersContainer {
        display: flex;
        flex:1;
        flex-direction:column;
        margin:30px;
        padding:20px;
        padding-top:20px;
        border-radius:20px;
        max-height:500px;
        /* overflow-y: scroll; */
        background-color: white;
        .dropdown-item {
            cursor: pointer;
        }
    }
    .boxTitle {
        font-size:25px;
        margin:10px;
    }
    .docsContainer {
        display: flex;
        flex:1;
        flex-direction:column;
        margin:40px;
        padding:20px;
        padding-top:20px;
        border-radius:20px;
        max-height:500px;
        /* overflow-y: scroll; */
        background-color: white;
        .mobile-see-more {
            /* display: none; */
            transition: 0.3s ease-in-out;
            &:hover {
                background-color:rgba(0, 0, 0, 0.3);
                cursor:pointer;
                color:black;
            }
        }
    }

    .collapsible .content {
    padding: 6px;
    background-color: green;
    }
    /* background-color: #dddddd; */
    padding: 6px;
    /* cursor: pointer; */
    .row{
        span {
            display: flex;
            flex:1;
        }
    }

    .docCreateContainer {
        display:flex;
        justify-content: flex-end;
        padding-right:100px;
        font-size:18px;
        color: #302ce9;
        text-decoration:underline;
        
        .docCreate {
            padding:4px;
            &:hover {
                cursor:pointer;
            }
        }
    }
    
    @media screen and (min-width: 280px) and (max-width: 720px) {
        .cardsContainer {
            flex-direction: column;
        }
        .usersDiv {
            flex-direction: column;
        }
        .usersContainer{
            margin-left:0px;
            margin-right:0px;
            padding:10px;
        }
        .docsContainer {
            margin-left:0px;
            margin-right:0px;
            .mobile-see-more {
                display: block;
            }
            .see-more: {
                /* display: none; */
            }
        }
        .row {
            display:flex;
            flex-direction:column;
        }
    }
    @media screen and (min-width: 720px) and (max-width: 860px) {
        .cardsContainer {
            flex-direction: column;
        }
        .usersDiv {
            flex-direction: column;
        }
        .usersContainer{
            margin-left:10px;
            margin-right:10px;
        }
        .docsContainer {
            .mobile-see-more {
                display: block;
                padding:5px;
            }
            .see-more {
                display: none;
            }
        }
    }

`