import React, { useEffect } from "react";
import Hero from "../components/Hero";
import ScrollToTop from "../components/ScrollToTop";
import Services from "../components/Services";
import scrollreveal from "scrollreveal";
import Categories from "../components/Categories";
export default function Home() {
  
  // const getValidatedDocs = async() => {
  //   let validatedDocsArr = [];
  //   const q = query(collection(db, "resources/documents/validated"), orderBy('createdAt', 'desc'), limit(6));
  //   const querySnapshot = await getDocs(q);
  //   querySnapshot.forEach((doc) => {
  //   validatedDocsArr.push({...doc.data(), id: doc.id})
  //   });
  //   setValidatedDocs(validatedDocsArr);
  // };
  useEffect(() => {
    // getValidatedDocs()
    const sr = scrollreveal({
      origin: "top",
      distance: "10px",
      duration: 1000,
      reset: true,
    });
    sr.reveal(
      `
        nav,
        #hero,
        #services,
        #recommend,
        #testimonials,
        footer
        `,
      {
        opacity: 50,
        interval: 100,
      }
    );
  }, []);
  
  return (
    <div>
      <ScrollToTop />
      <Hero />
      {/* <Recommend documents={validatedDocs} /> */}
      {/* <Testimonials /> */}
      <Categories/>
      <Services />
      {/* <Footer /> */}
      {/* <Link to="upload">Uploader un fichier</Link>
      <Link to="download">Télécharger un fichier</Link> */}
    </div>
  );
}
