import { collection, getDocs, orderBy, query, where } from "@firebase/firestore";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { db } from "../App";
import home1 from "../assets/home1.jpg";
import home2 from "../assets/home2.jpg";
import home3 from "../assets/home3.jpg";
import home4 from "../assets/home4.jpg";
import texts from "../assets/texts";
import { UserContext } from "../context/UserContext";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


export default function Hero() {
  const [titleInclude, setTitleinclude] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const history = useHistory();
  const {language} = useContext(UserContext);
  const defaultFromDate = new Date('January 01, 2000 00:00:00');
  const defaultToDate = new Date('December 31, 2050 23:59:59');


  const getDate = (inputString) => {
    var parts =inputString.split('-');
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    var mydate = new Date(parts[0], parts[1] - 1, parts[2]); 
    return mydate
  };
  const getCorrespondingDocs = async() => {
    let validatedDocsArr = [];
    const q = query(
      collection(db, "resources/documents/validated"),
      orderBy('createdAt', 'desc'),
      where('createdAt', '>=', fromDate? getDate(fromDate) : defaultFromDate),
      where('createdAt', '<=', toDate? getDate(toDate) : defaultToDate),
    );
    
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
    validatedDocsArr.push({...doc.data(), id: doc.id})
    });

    
    return validatedDocsArr;
  };

  const onDocSearch = async () => {
    if(titleInclude || fromDate || toDate) {
      let documents = await getCorrespondingDocs();
      if (titleInclude) {
        documents = documents.filter(item => item.title.toLowerCase().includes(titleInclude.toLowerCase()))
      }
      console.log('Documents: ', documents);
      history.push({
        pathname:'./results',
        state:{documents}
      });
    }
  }

  return (
    <Section id="hero">
      <Carousel className="background" autoPlay infiniteLoop interval={5000} transitionTime={1000}>
        <div>
          <img src={home1} alt="" />
          {/* <span className="legend">Carousel 1</span> */}
        </div>
        <div>
          <img src={home2} alt="" />
        </div>
        <div>
          <img src={home3} alt="" />
        </div>
        <div>
          <img src={home4} alt="" />
        </div>
      </Carousel>
      <div className="content">
        <div className="title">
          <h1>{texts[language].hero.welcome}</h1>
          <p>
          {texts[language].hero.description}
          </p>
        </div>
        <div className="search">
          <div className="container">
            <label htmlFor="">{texts[language].hero.searchTitle}</label>
            <input onChange={(e) => setTitleinclude(e.target.value)} type="text" placeholder={texts[language].hero.searchTitlePlaceholder} />
          </div>
          <div className="container">
            <label htmlFor="">{texts[language].hero.fromDateLabel}</label>
            <input onChange={(e) => setFromDate(e.target.value)} type="date" />
          </div>
          <div className="container">
            <label htmlFor="">{texts[language].hero.toDateLabel}</label>
            <input onChange={(e) => setToDate(e.target.value)} type="date" />
          </div>
          <button onClick={onDocSearch}>{texts[language].hero.search}</button>
        </div>
      </div>
    </Section>
  );
}

const Section = styled.section`
  position: relative;
  margin-top: 2rem;
  width: 100%;
  height: 400px;
  overflow: hidden;
  .background {
    height: 100%;
    img {
      width: 100%;
      /* filter: brightness(60%); */
    }
  }
  .content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    .title {
      color: white;
      h1 {
        font-size: 3rem;
        letter-spacing: 0.2rem;
      }
      p {
        text-align: center;
        padding: 0 30vw;
        margin-top: 0.5rem;
        font-size: 1.3rem;
      }
    }
    .search {
      display: flex;
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: rgba(100, 100, 111, 0.2) 0px -2px 9px 0px;
      padding: 0.5rem;
      border-radius: 0.5rem;
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 1.5rem;
        label {
          font-size: 1.1rem;
          color: #1C7D2D;
        }
        input {
          background-color: transparent;
          border: none;
          text-align: center;
          color: black;
          &[type="date"] {
            padding-left: 3rem;
          }

          &::placeholder {
            color: black;
          }
          &:focus {
            outline: none;
          }
        }
      }
      button {
        padding: 1rem;
        cursor: pointer;
        border-radius: 0.3rem;
        border: none;
        color: white;
        background-color: #177d2a;
        font-size: 1.1rem;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: #066124;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 980px) {
    height: 28rem;
    .background {
      background-color: white;
      img {
        height: 100%;
      }
    }
    .content {
      .title {
        padding-top:16px;
        h1 {
          font-size: 1rem;
          margin-left:20px;
          margin-right:20px;
        }
        p {
          font-size: 0.8rem;
          padding: 10px;
        }
      }
      .search {
        flex-direction: column;
        padding: 0.8rem;
        margin-top:-25px;
        gap: 0.8rem;
        /* padding: 0; */
        .container {
          padding: 0 0.8rem;
          input[type="date"] {
            padding-left: 1rem;
          }
        }
        button {
          padding: 1rem;
          font-size: 1rem;
        }
        /* display: none; */
      }
    }
  }
`;
