import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import pdf from "../assets/pdf.png";
import docx from "../assets/docx.png";
import xlsx from "../assets/xlsx.png";
import pptx from "../assets/pptx.png";
import txt from "../assets/txt.png";
import nofile from "../assets/nofile.png"
import { BiMap } from "react-icons/bi";
import UploadParams from "../assets/UploadParams";
import { UserContext } from "../context/UserContext";
import Modal from "react-modal";
import Select from "react-select";
import { addDoc, collection, query, getDocs } from "@firebase/firestore";
import { db } from "../App";
import { BsFillCloudDownloadFill } from "react-icons/bs";
import { AuthContext } from "../context/AuthContext";

const CustomInput = ({type, placeholder, size=12, value, isDisabled, onChange, label}) => {
  const [focused, setFocused] = useState(false);
  const {language} = useContext(UserContext);
  return(
      <div style={{marginBottom:25, display:isDisabled ? 'none' : undefined}}>
          <div className={"col-md-1"}></div>
          <div className={"col-md-" + size}>
            <label style={{display:'flex', flex:1, flexDirection:'column'}}>
              {label ? 
                <span style={{marginTop:-13, marginBottom:3, marginLeft:6,fontSize:16.5}}>
                    {label}
                </span>
                : null
                }
                <input
                disabled={isDisabled}
                onChange={onChange}
                placeholder={placeholder}
                style={{borderRadius:10, height:38, borderColor:focused ? 'blue' : '#cccccc', borderWidth: focused ? 2:1, color:isDisabled? '#677' :'black'}}
                type={type ? type : "text"}
                id="form12"
                className="form-control shadow-none"
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                value={value}
                />
              </label>
            </div>
          <div className={"col-md-" + 11-size}></div>
          
      </div>
  )
}

function customTheme (theme) {
  return {
      ...theme,
      borderRadius:10,
      borderWidth:5,
      colors:{
          ...theme.colors,
          primary25: '#cccccc',
          primary:'green'
      }
  }
}

const CustomSelect = ({placeholder, size=12, options, isDisabled, value, setState, noOptionsMessage}) => {
  return(
  <div style={{marginBottom:25, display: isDisabled ? 'none' : undefined}}>
      <div className={"col-md-1"}></div>
      <div className={"col-md-" + size}>
          <Select
              isDisabled={isDisabled}
              noOptionsMessage={noOptionsMessage}
              options={ options }
              placeholder={placeholder}
              theme={customTheme}
              onChange={setState}
              value={value}
              />
      </div>
      <div className={"col-md-"+ 11-size}></div>
      
  </div>)
}

const CustomModal =({document, modalOpen, onConfirm, onCancel, organisations}) => {
  let subtitle;

  // const [cookies, setCookies, removeCookies] = useCookies(['docDownload']);

  const docDownload = JSON.parse(localStorage.getItem('docDownload'));

  const [email, setEmail] = useState(docDownload?.email || '');
  const [name, setName] = useState(docDownload?.name || '');
  const [warning, setWarning] = useState(null);
  const [selectValue, setSelectValue] = useState(docDownload?.organisation || null);
  const [remember, setRemember] = useState(false);
  
  let flatOrgs = [];
  if(organisations) {
    let keysCountry = Object.keys(organisations);
    keysCountry.forEach(country => {
      flatOrgs = flatOrgs.concat(organisations[country]);
    });

    console.log('Organisations: ', flatOrgs );
  }
  

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      // marginRight: '-50%',
      borderRadius:10,
      transform: 'translate(-50%, -50%)',
      minWidth:360,
      overflow:'visible'
    },
  };

  const selectValues = [
    {label: 'BRAINFOREST', value:'BRAINFOREST'},
    {label: 'CAGDF', value:'CAGDF'},
    {label: 'CIEDD', value:'CIEDD'},
    {label: 'CJJ', value:'CJJ'},
    {label: 'FLAG', value:'FLAG'},
    {label: 'FODER', value:'FODER'},
    {label: 'OGF', value:'OGF'},
    {label: 'RNN', value:'RNN'},
    {label: 'SAJLD', value:'SAJLD'},
  ];

  const emailCond = email.includes('@')
  && email.includes('.')
  && email.trim('@', '.') === email
  && !email.includes('@.', '.@')
  && email.length >=5
  && !email.includes(['/'])
  ; 

  const onDownload = () => {
    setWarning(null);
    console.log("Email: ", email);
    if (!email) {
      setWarning("Veuillez renseigner l'addresse email");
    } else {
      if(emailCond) {
        const docDownloadObj = {
          name:name,
          email:email,
          organisation:selectValue
        }
        if(remember) {
          localStorage.setItem('docDownload', JSON.stringify(docDownloadObj));
        }
        sessionStorage.setItem('docDownload', JSON.stringify(docDownloadObj));
        onConfirm(document, docDownloadObj);
        console.log("Downloading...");

      } else {
        setWarning("L'addresse email est incorrecte");
      }
    }
  }

  return (
    <div>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        ariaHideApp={false}
        isOpen={modalOpen}
        closeTimeoutMS={200}
        onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        // contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Télécharger le document</h2>
        <div>
          <CustomSelect
            value={selectValue}
            setState={setSelectValue}
            // options={selectValues}
            options={flatOrgs || []}
            placeholder="Organisation (facultatif)"
          />
          <CustomInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nom (facultatif)"
            />
          <CustomInput
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Addresse email (obligatoire)"
            value={email}
          />
        {warning? <span style={{color:'red'}}> {warning}</span>: null}
        </div>
        
        <div>
          <input
            className="form-check-input shadow-none"
            type="checkbox"
            style={{marginLeft:5, marginRight:10}}
            checked={remember}
            onChange={() => setRemember(!remember)}
            />
            <span onClick={() => setRemember(!remember)} style={{cursor:'default'}}>
              Se souvenir de mes choix
            </span>
        </div>
        
        <div style={{display:'flex', justifyContent:'flex-end', gap:12, marginTop:10}}>
          {
            emailCond && document?
            <a href={document.docUrl} onClick={onDownload} className="btn btn-success shadow-none" >Télécharger</a>
          :
            <button className="btn btn-success shadow-none disabled" >Télécharger</button>
          }
          <button onClick={onCancel} className="btn btn-warning shadow-none">Annuler</button>
        </div>
      </Modal>
    </div>
  );
}

export default function Recommend({documents, title, loading}) {
  const {language} = useContext(UserContext);
  const {user} = useContext(AuthContext);
  
  const [modalOpen, setModalOpen] = useState(false);
  const [docToDownload, setDocToDownload] = useState(null);
  const [formParams, setFormParams] = useState(null);

  
  const dropProperties = ['domain', 'OIType', 'reportType', 'period', 'fromValidityPeriod', 'toValidityPeriod'];
  const inputProperties = ['concernedTitles', 'editor', 'journal', 'validityPeriod', 'publicationDate'];
  
  async function getFormParams () {
    let uploadParams = {};
    const q = query(collection(db, "uploadparams"));

    const params = await getDocs(q);
    params.forEach(lang => {
        uploadParams[lang.id] = lang.data()
    });
    
    if(Object.keys(uploadParams).length ===0) {
        uploadParams = null;
    }

    setFormParams(uploadParams);
}
  const onDownload = async (document, userParams) => {
    // console.log('User params: ', userParams);
    let newUserParams = {email:userParams.email||'', name:userParams.name||'', organisation:userParams.organisation?.value||''};
    setModalOpen(false);
    const uploadObj = {docId:document.id, title:document.title, downloadedAt: new Date(), userParams:newUserParams};
    await addDoc(collection(db, 'resources','statistics', 'downloads'), uploadObj);
    console.log('Document marked in db. Download URL: ', document.docUrl);
    // const downloadFile = (async (url, path) => {
    //   const res = await fetch(url);
    //   const fileStream = fs.createWriteStream(path);
    //   await new Promise((resolve, reject) => {
    //       res.body.pipe(fileStream);
    //       res.body.on("error", reject);
    //       fileStream.on("finish", resolve);
    //     });
    //   });
    // downloadFile(document.docUrl, './')
  };
  
  const icons = {
    doc:docx,
    docx:docx,
    pdf:pdf,
    ppt:pptx,
    pptx:pptx,
    txt:txt,
    xls:xlsx,
    xlsx:xlsx,
  };

  const docTypes = {
    'pdf':'PDF',
    'doc':'Word',
    'docx':'Word',
    'ppt':'PowerPoint',
    'pptx':'PowerPoint',
    'xls':'Excel',
    'xlsx':'Excel',
    'txt':language==='fr'?'Texte':'Text'
  };

  const getReadableDate = (date) => {
    const months = {
        en:['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September','October', 'November', 'December'],
        fr:['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin',
            'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
        };
    const readableDate = date.getDate() + ' ' + months[language][date.getMonth()]+' '+date.getFullYear();
    return readableDate;
};

const sessionDownload = JSON.parse(sessionStorage.getItem('docDownload'));
  console.log('Session download: ', sessionDownload);
  useEffect(() => {
    getFormParams();
  }, [])
  return (
    <Section id="recommend">
      <CustomModal
        document={docToDownload}
        modalOpen={modalOpen}
        onCancel={() => setModalOpen(false)}
        onConfirm={onDownload}
        organisations={formParams? formParams[language].organisation : null}
        />
      {title? 
        <div className="title">
          <h2 style={{marginBottom:50}}>{title}</h2>
        </div>
        : null
      }
      {
        documents?.length?
        <div className="destinations">
          {documents?.map((document) => {
            const DownloadButton = () => {
              if(sessionDownload) {
                return (
                  <a 
                    onClick={() => onDownload(document, sessionDownload)} 
                      href={document.docUrl}
                      className="downloadContainer"
                      >
                    <BsFillCloudDownloadFill color="#0169b2" size={40}/>
                  </a>
                )
              } else {
                return (
                  <div 
                    onClick={() => {
                      setDocToDownload(document);
                      setModalOpen(true);
                    }} 
                      // href={document.docUrl}
                      className="downloadContainer"
                      >
                    <BsFillCloudDownloadFill color="#0169b2" size={40}/>
                  </div>
                )
              }
            }
            return (
              <div className="destination">
                <div style={{display:'flex',justifyContent:'space-between'}}>
                  <img className="image" src={icons[document.type]} alt="" />
                  <DownloadButton/>
                </div>
                <h3>{document.title}</h3>
                <div style={{display:'flex', justifyContent:'flex-start', fontSize:17.5, fontWeight:'500'}}>
                  <BiMap size={27} />
                  {formParams? formParams[language].country.filter(country => country.value=== document.country)[0].label:null}
                  {' '}({formParams? formParams[language].organisation[document.country].filter(org => org.value === document.organisation)[0].label:null})
                  
                </div>
                <p style={{fontSize:17}}>
                  {UploadParams[language].category.filter(item => item.value === document.category)[0].label}
                </p>
                {/* <div className="info"> */}
                {/* {language==='fr'?<span>• Document {docTypes[document.type]}</span>
                :<span>• {docTypes[document.type]} Document</span>} */}
                  {dropProperties.map(prop => {
                    if (document[prop]) {
                      if (prop === 'reportType') {
                        return <span>• {UploadParams[language].reportType.values[document.category].filter(obj => obj.value === document.reportType)[0].label}</span>
                      } else if(prop==='period') {
                        return <span>• {language==='fr'? 'Période: ':'Period: '} {document['period']}</span>
                      } else if(prop === 'fromValidityPeriod') {
                        return <span>• {language==='fr'? 'Valide du':'Valid from the'} {document['fromValidityPeriod']} {language==='fr'? 'au':'to the'} {document['toValidityPeriod']}</span>
                      } else if(prop === 'toValidityPeriod') {
                        return null;
                      } else {
                        return <span>• {UploadParams[language][prop].values.filter(obj => obj.value === document[prop])[0].label}</span>
                      }
                    } else {
                      return null;
                    }
                  })}
                  {inputProperties.map(prop => {
                    if (document[prop]) {
                      if (prop === 'publicationDate') {
                        return <span>• {language==='fr'? 'Publié le ':'Published on the '} {getReadableDate((new Date(document['publicationDate'])))}</span>
                      } else {
                        return <span>• {document[prop]}</span>
                      }
                    } else {
                      return null;
                    }
                  })}
                  {/* <div className="services">
                    <img src={info1} alt="" />
                    <img src={info2} alt="" />
                    <img src={info3} alt="" />
                  </div> */}
                  {/* <h4>{document.cost}</h4> */}
                {/* </div> */}
                {/* <div className="distance"> */}
                  {/* <span>1000 Kms</span> */}
                  {/* <span>{document.duration}</span> */}
                {/* </div> */}
              </div>
            );
          })}
        </div>
        :
        <div className="container" style={loading?{display:'flex',alignItems:'center', justifyContent:'center', marginTop:-80, marginBottom:-20}:null}>
          {
            loading?
            <div style={{color:'#1C7D2D', height:200, width:200, alignSelf:'center'}} class="spinner-grow"/>
              :
            <div className="row">
              {/* <div> */}
                <img className="col" style={{maxHeight:500, maxWidth:'100%'}} src={nofile}/>
              {/* </div> */}
              <h3 className="col" style={{paddingTop:30, textAlign:'center'}}>{language==='fr'?'Aucun document correspondant.':'No corresponding documents.'}</h3>
            </div>
          }
        </div>

      }

      
    </Section>
  );
}

const Section = styled.section`
  padding: 2rem 0;
  margin-bottom:5rem;
  .title {
    text-align: center;
    margin-bottom:20px;
  }
  .packages {
    display: flex;
    justify-content: center;
    /* margin: 2rem 0; */
    ul {
      display: flex;
      list-style-type: none;
      width: max-content;
      li {
        padding: 1rem 2rem;
        border-bottom: 0.1rem solid black;
      }
      .active {
        border-bottom: 0.5rem solid #8338ec;
      }
    }
  }
  .destinations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    padding: 0 3rem;
    .downloadContainer {
      height:64px;
      display:flex;
      width:55px;
      border-radius:15px;
      background-color:rgba(71, 167, 42, 0.7);
      align-items:center;
      justify-content:center;
      transition: 0.3s ease-in-out;
      &:hover{
        cursor:pointer;
        box-shadow: rgba(0, 106, 179, 0.6) 0px 0px 5px;
        background-color:rgba(71, 167, 42, 0.3);
      }
    }
    .destination {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      background-color: #F5F5F5;
      border-radius: 1rem;
      transition: 0.3s ease-in-out;
      &:hover {
        /* transform: translateX(0.4rem) translateY(-1rem); */
        box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5px;
      }
      .image {
        /* height:200px; */
        height: 150px;
        resize: contain;
        margin-bottom:20px;
        /* border:1px solid black; */
      }
      .info {
        display: flex;
        flex-direction: column;
        font-size: 17px;
        .services {
          display: flex;
          gap: 0.3rem;
          img {
            /* border-radius: 1rem;
            background-color: #4d2ddb84;
            width: 2rem;
            /* padding: 1rem; */
            /* padding: 0.3rem 0.4rem; */ */
          }
        }
        display: flex;
        justify-content: space-between;
      }
      .distance {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 768px) {
    .packages {
      ul {
        li {
          padding: 0 0.5rem;
          font-size: 2vh;
          padding-bottom: 1rem;
        }
        .active {
          border-bottom-width: 0.3rem;
        }
      }
    }
    .destinations {
      grid-template-columns: 1fr;
      padding: 0;
    }
  }
`;
