import React, { useContext, useState } from "react";
import styled from "styled-components";
import logo from "../assets/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { VscChromeClose } from "react-icons/vsc";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import texts from "../assets/texts";
import { IoMdLogOut } from "react-icons/io";
import { auth } from "../App";

export default function Navbar() {
  const {user, setUser} = useContext(AuthContext);
  const {language} = useContext(UserContext);
  
  const [navbarState, setNavbarState] = useState(false);

  const history = useHistory()
  const EmailSignOut = ({email}) => {
    let noted = '';
    if(email.length >16) {
      noted+=email.slice(0,17) + '...';
    } else {
      noted = email;
    }

    const onLogOutPress = () => {
      const shouldLogOut = window.confirm(language==='fr'?'Voulez vous vraiment vous déconnecter de votre compte O2I-TRI ?':'Doy you really want to log out from your O2I-TRI account ?');
      
      if(shouldLogOut) {
        setNavbarState(false);
        localStorage.removeItem('storedUser');
        localStorage.removeItem('storedLanguage');
        auth.signOut();
        history.push({pathname:"/login", state:{loginPersist:true}});
        setUser(null);
      }
    }
    return (
      <div onClick={onLogOutPress} className="disconnect">
        {noted}
        <div style={{height:28, width:28, borderRadius:'50%', display:'flex', alignItems:'center', justifyContent:'center', marginLeft:5}}>
          <IoMdLogOut className="logOutIcon" size={24}/>
        </div>
      </div>
    )
  }
  return (
    <>
      <Nav>
        <div className="brand">
          <div className="container">
            {/* <Link to="./search" style={{paddingTop:5}} className="searchContainer">
              <BiSearch size={32} color='#ffffff' />
              <CgOptions size={19} style={{marginTop:-5, marginLeft:-5}} color="#ffffff" />
            </Link> */}
            <img style={{height:40, marginBottom:10, resize:'both'}} src={logo} alt="" />
            O2I - TRI
          </div>
          <div className="toggle">
            {navbarState ? (
              <VscChromeClose onClick={() => setNavbarState(false)} />
            ) : (
              <GiHamburgerMenu onClick={() => setNavbarState(true)} />
            )}
          </div>
        </div>

        <ul>
          <li>
            <a href="./#home">{texts[language].navbar.home}</a>
          </li>
          {/* <li>
            <a href="/#services">A propos</a>
          </li> */}
          {/* <li>
            <a href="/#testimonials">Testimonials</a>
          </li> */}
          <li>
            <Link to="/search">{texts[language].navbar.search}</Link>
          </li>
          <li>
            <Link to="./upload" >{texts[language].navbar.upload}</Link>
          </li>
          <li>
            <a href="./all">{texts[language].navbar.seeall}</a>
          </li>
          
          {
            user?.admin?
          <li>
            <Link to="./admin" >Admin</Link>
          </li>
            :
          null
          }
          <li>
            <a href="http://pa-oi.org">{language==='fr'?'Retour à PAOI':'Return to PAOI'}</a>
          </li>
        </ul>
        {!user?
          <Link to={{pathname:"/login", state:{loginPersist:true}}} className="connect">{language==='fr'?'Se connecter':'Connect'}</Link>
          :
          <EmailSignOut email={user.email}/>
        }
      </Nav>
      <ResponsiveNav state={navbarState}>
        <ul>
          <li>
            <a href="./#home" onClick={() => setNavbarState(false)}>
            {texts[language].navbar.home}
            </a>
          </li>
          {
            user?.admin?
          <li>
            <a href="./admin" onClick={() => setNavbarState(false)}>
              Admin
            </a>
          </li>
          :
          null
          }
          <li>
            <Link to="/search" onClick={() => setNavbarState(false)} >
            {texts[language].navbar.search}
            </Link>
          </li>
          <li>
            <Link to="./upload" onClick={() => setNavbarState(false)}>
            {texts[language].navbar.upload}
            </Link>
          </li>
          <li>
            <a href="./all" onClick={() => setNavbarState(false)}>
            {texts[language].navbar.seeall}
            </a>
          </li>
          <li>
            {!user?
              <Link to={{pathname:"/login", state:{loginPersist:true}}} onClick={() => setNavbarState(false)}>{language==='fr'?'Se connecter':'Connect'}</Link>
            :
            <EmailSignOut email={user.email}/>}
          </li>
          <li>
            <a href="http://pa-oi.org" onClick={() => setNavbarState(false)}>{language==='fr'?'Retour à PAOI':'Return to PAOI'}</a>
          </li>
        </ul>
      </ResponsiveNav>
    </>
  );
}

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .brand {
    .container {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
      font-size: 1.2rem;
      font-weight: 900;
      text-transform: uppercase;
    }
    .searchContainer {
      background-color:#0cc924;
      flex-direction:row;
      padding:3px;
      padding-left:7px;
      padding-right:7px;
      margin-right:30px;
      border-radius:20px;
      transition: 0.3s ease-in-out;
        &:hover {
          background-color: #088618;
        }
    }
    .toggle {
      display: none;
    }
  }
  ul {
    display: flex;
    gap: 1.3rem;
    list-style-type: none;
    li {
      a {
        text-decoration: none;
        color: #177d2a;
        font-size: 1.2rem;
        transition: 0.1s ease-in-out;
        padding: 3px 8px 3px 8px;
        border-radius:8px;
        &:hover {
          /* color: #088618; */
          background-color:rgba(71, 167, 42, 0.2);
        }
      }
      &:first-of-type {
        a {
          /* color: #088618;
          font-weight: 900; */
        }
      }
    }
  }
  .connect {
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 1rem;
    border: none;
    color: white;
    background-color: #177d2a;
    font-size: 1.1rem;
    text-decoration: none;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
    &:hover {
      background-color: #088618;
    }
  }
  .disconnect {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    font-size:17px;
    padding:2px 6px 2px 6px;
    border-radius:30px;
    transition: 0.3s ease-in-out;
    cursor:pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 4px;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5px;
    }
    div{
      transition: 0.3s ease-in-out;
      background-color:green;
      .logOutIcon {
        transition: 0.3s ease-in-out;
        color:white;
      }
    }
    
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .brand {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .toggle {
        display: block;
      }
    }
    .searchContainer {
      margin-right: 50px;
      margin-left: -70px;
      flex-direction: row;
      align-items: center;
    }
    ul {
      display: none;
    }
    .connect {
      display: none;
    }
    .disconnect {
      display: none;
    }
  }
`;

const ResponsiveNav = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  top: ${({ state }) => (state ? "50px" : "-400px")};
  background-color: white;
  /* height: 30vh; */
  width: 100%;
  align-items: center;
  transition: 0.3s ease-in-out;
  ul {
    list-style-type: none;
    width: 100%;
    li {
      width: 100%;
      margin: 1rem 0;
      margin-left: 2rem;

      a {
        text-decoration: none;
        color: #0077b6;
        font-size: 1.2rem;
        transition: 0.1s ease-in-out;
        &:hover {
          color: #023e8a;
        }
      }
      .disconnect {
        display:flex;
        flex-direction:row;
        text-decoration: none;
        color: #0077b6;
        font-size: 1.2rem;
        transition: 0.1s ease-in-out;
        &:hover {
          color: #023e8a;
        }
      }
      &:first-of-type {
        a {
          /* color: #023e8a;
          font-weight: 900; */
        }
      }
    }
  }
`;
