import React, { createContext, useState } from "react";


export const UserContext = createContext();

const UserContextProvider = ({children}) => {
    const [language, setLanguage] = useState(localStorage.getItem('storedLanguage') || 'fr');
    
    return (
        <UserContext.Provider
            value={{
                language,
                setLanguage
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export default UserContextProvider;