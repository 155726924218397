import React, { useContext, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { collection, getDocs, orderBy, query, Timestamp, where } from '@firebase/firestore';
import { db } from '../App';
import { UserContext } from '../context/UserContext';
import styled from 'styled-components';
import {AiOutlineFundView} from 'react-icons/ai';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    legend: {
      labels: {
        font: {
          size:15,
          weight:'600'
        },
        padding:30
      }
    }
  },
};

const labels = {
  en:[
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ],
  fr:[
    'Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ]
};



const Statistics = () => {
  const [downlDocsStats, setDownlDocsStats] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [yearlyDownloadInfos, setYearlyDownloadInfos] = useState([]);
  const {language} = useContext(UserContext);
  const currentMonth = (new Date()).getMonth();
  const currentYear = (new Date()).getFullYear();

  const sortedLabels = labels[language].slice(currentMonth+1).map(label => label+= ` ${currentYear-1}`)
  .concat(labels[language].slice(0, currentMonth+1).map(label => label+= ` ${currentYear}`));

  const data = {
    labels:sortedLabels,
    datasets: [
      {
        lineTension: 0.2,
        label: language==='fr'?'Téléchargements':'Downloads',
        data:downlDocsStats,
        borderColor: 'rgb(28,125,45)',
        backgroundColor: 'rgba(28,125,45, 0.5)',
        fill:true,
        pointRadius: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 7],
      },
  
    ],
  };
  
  const getDownloadStats = async() => {
    let downlDocsArr = [];

    function subStractYears(numOfYears, date=new Date()) {
      date.setFullYear(date.getFullYear()-numOfYears);
      return date
    };

    const q = query(
      collection(db, 'resources/statistics/downloads'),
      orderBy('downloadedAt', 'asc'),
      where('downloadedAt', '>=', Timestamp.fromDate(subStractYears(1))) //Get the documents downloads that are at most one year old
    );
    
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
    downlDocsArr.push({...doc.data(), id: doc.id})
    });

    let docsStats = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (let docIdx in downlDocsArr) {
      const downlMonth = downlDocsArr[docIdx].downloadedAt.toDate().getMonth();
      docsStats[downlMonth]+=1;
    }
    
    // console.log('Statistics:  ', docsStats);
    const sortedStats = docsStats.slice(currentMonth+1).concat(docsStats.slice(0, currentMonth+1));
    console.log(downlDocsArr);
    setYearlyDownloadInfos(downlDocsArr.reverse());
    setDownlDocsStats(sortedStats);
  };


  const getReadableDate = (date) => {
    const months = {
        en:['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September','October', 'November', 'December'],
        fr:['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin',
            'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
        };
    const readableDate = date.getDate() + ' ' + months[language][date.getMonth()]+' '+date.getFullYear();
    return readableDate;
  };

  useEffect(() => {
    getDownloadStats();
  }, []);
  return(
    <Container style={{marginBottom:40}} className="container-md">
      <div className="chartContainer">
        <Line options={options} data={data} />
      </div>
      <div className="mobileInfo">
        <AiOutlineFundView size={300} style={{marginTop:60, marginLeft:20}} color="#ccc"/>
        <span style={{fontSize:18, textAlign:'center'}}>Visible uniquement sur pc et tablette</span>
      </div>
      <div className="downloadInfosContainer">
        <h3 style={{margin:20, color:'white'}}>
          Durant les 12 derniers mois...
        </h3>
        <div className="itemsContainer">
          {
            yearlyDownloadInfos.map(downloadInfo => {
              console.log('Download info', downloadInfo.userParams.email);
              return(
              <div className="itemContainer">
                  <span>
                    Document: {downloadInfo.title}
                  </span>
                <div className="downloadInfosItem">
                  <span>
                    Email: {downloadInfo.userParams.email?downloadInfo.userParams.email:language==='fr'?'Non spécifié':'Not specified'}
                  </span>
                  <span>
                  {language==='fr'?'Téléchargé le: ':'Downloaded on: '}{getReadableDate(downloadInfo.downloadedAt.toDate())}
                  </span>
                  <span>
                    {language==='fr'?'Nom: ':'Name: '}{downloadInfo.userParams.name?downloadInfo.userParams.name:language==='fr'?'Non spécifié':'Not specified'}
                  </span>
                  <span>
                    Organisation: {downloadInfo.userParams.organisation?downloadInfo.userParams.organisation:language==='fr'?'Non spécifié':'Not specified'}
                  </span>
                </div>
              </div>
            )})
          }
        </div>
      </div>
    </Container> 
  );
}

export default Statistics;

const Container = styled.div`
  align-items:center;
  .downloadInfosContainer {
    background-color:gray;
    padding:30px;
    margin-top:80px;
    
    .itemsContainer {
      display:flex;
      flex-direction:column;
      align-items:center;
      max-height:600px;
      overflow-y:scroll;
      .itemContainer{
        background-color:white;
        margin:10px;
        width:95%;
        border-radius:8px;
        padding-left:20px;
        padding-right:20px;
        padding-top:10px;
        padding-bottom:10px;
        .downloadInfosItem {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
  @media screen and (max-width: 720px) {
    .chartContainer {
      display: none;
    }
    .downloadInfosContainer {
      padding:10px;
      .itemsContainer {
        .itemContainer {
          width:94%;
          padding-left:5px;
          padding-right:5px;
          .downloadInfosItem {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
  @media screen and (min-width: 720px) {
    .mobileInfo {
      display: none;
    }
  }
`