import React, { useContext } from "react";
import { useLocation } from "react-router";
import Recommend from "../components/Recommend";
import { UserContext } from "../context/UserContext";
import PageNotFound from "./PageNotFound";

const Results = () => {
    const {language} = useContext(UserContext);
    const title = language =='fr'? "Résultats de la recherche":'Search results'
    const params = useLocation().state;
    return params?.documents ?<Recommend title={params.title ||title} documents={params.documents} /> : <PageNotFound/>
}

export default Results;