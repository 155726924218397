import React, { useContext } from "react";
import styled from "styled-components";
import { GrDocumentStore } from 'react-icons/gr';
import { AiOutlineUserSwitch } from 'react-icons/ai';
import { IoIosTimer } from 'react-icons/io';
import {BiSearch} from 'react-icons/bi';
import texts from "../assets/texts";
import { UserContext } from "../context/UserContext";

export default function Services() {
  const {language} = useContext(UserContext);
  return (
    <Section id="services">
        <div className="service">
          <div className="icon">
            <GrDocumentStore size={32}/>
          </div>
          <h3>{texts[language].services.service1Title}</h3>
          <p>{texts[language].services.service1Subtitle}</p>
        </div>
        <div className="service">
          <div className="icon">
            <AiOutlineUserSwitch size={32}/>
          </div>
          <h3>{texts[language].services.service2Title}</h3>
          <p>{texts[language].services.service2Subtitle}</p>
        </div>
        <div className="service">
          <div className="icon">
            <IoIosTimer size={34}/>
          </div>
          <h3>{texts[language].services.service3Title}</h3>
          <p>{texts[language].services.service3Subtitle}</p>
        </div>
        <div className="service">
          <div className="icon">
            <BiSearch size={34}/>
          </div>
          <h3>{texts[language].services.service4Title}</h3>
          <p>{texts[language].services.service4Subtitle}</p>
        </div>
    </Section>
  );
}

const Section = styled.section`
  padding: 5rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  .service {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    background-color: aliceblue;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    &:hover {
      transform: translateX(0.4rem) translateY(-1rem);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .icon {
      img {
        height: 2.4rem;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 720px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
