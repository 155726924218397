import React, { useContext, useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { query, doc, updateDoc, collection, getDocs, getDocsFromServer, where, deleteDoc } from '@firebase/firestore';
import styled from 'styled-components';
import Select from 'react-select';
import { UserContext } from '../context/UserContext';
import {IoTrashBin} from 'react-icons/io5';
import { db } from '../App';
import { deleteObject, getStorage, ref } from '@firebase/storage';

function customTheme (theme) {
    return {
        ...theme,
        borderRadius:10,
        borderWidth:5,
        colors:{
            ...theme.colors,
            primary25: 'rgb(28,125,45)',
            primary:'green'
        }
    }
}

const CustomInput = ({type, placeholder, value, isDisabled, onChange}) => {
    const [focused, setFocused] = useState(false);
    return(
        <div style={{width:400, marginBottom:6, marginTop:6, display: isDisabled ? 'none' : undefined}}>
            <input
                disabled={isDisabled}
                onChange={onChange}
                placeholder={placeholder}
                style={{borderRadius:10, height:38, borderColor:focused ? 'rgb(28,125,45)' : '#cccccc', borderWidth: focused ? 2:1, color:isDisabled? '#677' :'black'}}
                type="text"
                id="form12"
                className="form-control shadow-none"
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                value={value}
            />

        </div>
    )
}

const CustomSelect = ({placeholder, size, options, isDisabled, value, setState, noOptionsMessage}) => {
    return(
    <div style={{width:400, marginBottom:10, display: isDisabled ? 'none' : undefined}}>
        <Select
            isDisabled={isDisabled}
            noOptionsMessage={noOptionsMessage}
            options={ options }
            placeholder={placeholder}
            theme={customTheme}
            onChange={setState}
            value={value}
            />   
    </div>)
}


function FormUpgrade() {
    const {language} = useContext(UserContext);
    const [formData, setFormData] = useState(null);

    const [addCountryCode, setAddCountryCode] = useState('');
    const [addCountryNameFr, setAddCountryNameFr] = useState('');
    const [addCountryNameEn, setAddCountryNameEn] = useState('');
    const [addingCountry, setAddingCountry] = useState(false);
    const [applyingPeriod, setApplyingPeriod] = useState(false);

    const [orgCountry, setOrgCountry] = useState(null);
    const [organisation, setOrganisation] = useState('');
    const [addOrgSigle, setAddOrgSigle] = useState('');
    const [addOrgFr, setAddOrgFr] = useState('');
    const [addOrgEn, setAddOrgEn] = useState('');
    const [addingOrg, setAddingOrg] = useState(false);
    const [orgError, setOrgError] = useState('');
    const [periodError, setPeriodError] = useState('');
    const [initialPeriod, setInitialPeriod] = useState('');
    const [finalPeriod, setFinalPeriod] = useState('');

    let periodOptions = [];
    for (let i =1980; i< 2050; i++){
        periodOptions.push({label: i.toString(), value:i})
    }

    let finalPeriodOptions=[];
    if(!initialPeriod?.value) {
        finalPeriodOptions=periodOptions;
    } else {
        finalPeriodOptions = periodOptions.filter(item => item.value >initialPeriod.value);
    }
    async function getFormParams () {
        let uploadParams = {};
        const q = query(collection(db, "uploadparams"));

        const params = await getDocs(q);
        params.forEach(lang => {
            uploadParams[lang.id] = lang.data()
        });
        
        if(Object.keys(uploadParams).length ===0) {
            uploadParams = null;
        }
        setFormData(uploadParams);
        const initPer = uploadParams?.en.period?.initialPeriod||2010;
        const finalPer = uploadParams?.en.period?.finalPeriod||2035;
        setInitialPeriod({label:initPer, value:initPer});
        
        setFinalPeriod({label:finalPer, value:finalPer});
    }

    const deleteDocGroup = async({key, value}) => {
        console.log('Key: ', `"${key}"`, 'Value: ', `"${value}"`)
        const qEdit = query(collection(db, 'resources/documents/edit'), where(key, '==', value));
        const qWaiting = query(collection(db, 'resources/documents/waiting'), where(key, '==', value));
        const qValidated = query(collection(db, 'resources/documents/validated'), where(key, '==', value));

        let ungroupedEdit = await getDocsFromServer(qEdit);
        let ungroupedWaiting = await getDocsFromServer(qWaiting);
        let ungroupedValidated = await getDocsFromServer(qValidated);
        let ungroupedPromiseArr = [];
        let ungroupedFilesArr = [];
        
        ungroupedEdit.forEach(item => {
            const fileRef = ref(getStorage(), item.data().docUrl);
            ungroupedPromiseArr.push(deleteDoc(item.ref));
            ungroupedFilesArr.push(deleteObject(fileRef));
        });
        ungroupedWaiting.forEach(item => {
            const fileRef = ref(getStorage(), item.data().docUrl);
            ungroupedPromiseArr.push(deleteDoc(item.ref));
            ungroupedFilesArr.push(deleteObject(fileRef));
        });
        ungroupedValidated.forEach(item => {
            const fileRef = ref(getStorage(), item.data().docUrl);
            ungroupedPromiseArr.push(deleteDoc(item.ref));
            ungroupedFilesArr.push(deleteObject(fileRef));
        });

        try {
            await Promise.all(ungroupedFilesArr);
            await Promise.all(ungroupedPromiseArr);
        } catch (error) {
            console.log(error);
        }
    }

    async function onAddCountry () {
        if (!addingCountry && formData) {
            const addCondition = addCountryCode.length===3 && addCountryNameEn&&addCountryNameFr?true:false;
            if(addCondition) {
                setAddingCountry(true);
                let newFormData = await formData;
                newFormData.fr.country.push({label:addCountryNameFr, value:addCountryCode});
                newFormData.en.country.push({label:addCountryNameEn, value:addCountryCode});
                
                const qfr = query(doc(db, "uploadparams/fr"));
                await updateDoc(qfr, {'country':newFormData.fr.country});
                const qen = query(doc(db, "uploadparams/en"));
                await updateDoc(qen, {'country':newFormData.en.country});
    
                setFormData(newFormData);
                setAddCountryCode('');
                setAddCountryNameFr('');
                setAddCountryNameEn('');
                
                setAddingCountry(false);
            }

        }
    };


    async function onRemoveCountry (country) {
        if(!addingCountry && formData) {
            const confirmation = window.confirm(language==='fr'?'Si vous faites ça, tous les documents de ce pays seront supprimés de la plateforme. Continuer?'
                : 'If you continue, all documents from this country will be removed from the platform. Proceed?');
            
            if(confirmation) {
                setAddingCountry(true);
                let newFormData = await formData;
                newFormData.fr.country = newFormData.fr.country.filter(item => item.value != country);
                newFormData.fr.organisation[country] = [];
                newFormData.en.country = newFormData.en.country.filter(item => item.value != country);
                newFormData.en.organisation[country] = [];
        
                await deleteDocGroup({key:'country', value:country});
                const qfr = query(doc(db, "uploadparams/fr"));
                await updateDoc(qfr, {'country':newFormData.fr.country, organisation:newFormData.fr.organisation});
                const qen = query(doc(db, "uploadparams/en"));
                await updateDoc(qen, {'country':newFormData.en.country, organisation:newFormData.en.organisation});
        
                setFormData({...newFormData});
    
                setAddingCountry(false);
            }
        }
    };

    async function onAddOrganisation() {
        if(orgError) setOrgError('');
        if(!addingOrg && formData) {
            const addCondition = addOrgSigle && addOrgEn && addOrgFr?true:false;
            
            if(orgCountry?.value && addCondition) {
                setAddingOrg(true);
                console.log('Adding organisation...')
                let newFormData = await formData;

                if(!newFormData.fr.organisation[orgCountry.value]) newFormData.fr.organisation[orgCountry.value] =[];
                if(!newFormData.en.organisation[orgCountry.value]) newFormData.en.organisation[orgCountry.value] =[];
                
                newFormData.fr.organisation[orgCountry.value].push({label:addOrgFr, value:addOrgSigle});
                newFormData.en.organisation[orgCountry.value].push({label:addOrgEn, value:addOrgSigle});
                
    
                const qfr = query(doc(db, "uploadparams/fr"));
                await updateDoc(qfr, {organisation:newFormData.fr.organisation});
                const qen = query(doc(db, "uploadparams/en"));
                await updateDoc(qen, {organisation:newFormData.en.organisation});
    
                setFormData(newFormData);
                setAddOrgSigle('')
                setAddOrgFr('');
                setAddOrgEn('');
                
                setAddingOrg(false);
            } else if (!orgCountry?.value) {
                setOrgError(language==='fr'?'Veuillez selectionner un pays':'Please choose a country');
            }

        }
    };

    async function onRemoveOrganisation (organisation) {
        if(!addingOrg && formData) {
            const confirmation = window.confirm(language==='fr'?'Si vous faites ça, tous les documents de cette organisation seront supprimés de la plateforme. Continuer?'
                : 'If you continue, all documents from this organisation will be removed from the platform. Proceed?');

            if(confirmation) {
                setAddingOrg(true);
                let newFormData = await formData;
                newFormData.fr.organisation[orgCountry.value] = newFormData.fr.organisation[orgCountry.value]
                    .filter(item => item.value != organisation);
                newFormData.en.organisation[orgCountry.value] = newFormData.en.organisation[orgCountry.value]
                    .filter(item => item.value != organisation);
        
                await deleteDocGroup({key:'organisation', value:organisation});
                const qfr = query(doc(db, "uploadparams/fr"));
                await updateDoc(qfr, {organisation:newFormData.fr.organisation});
                const qen = query(doc(db, "uploadparams/en"));
                await updateDoc(qen, {organisation:newFormData.en.organisation});
                
                setFormData({...newFormData});
    
                setAddingOrg(false);
            }
            
        }
    };

    const onPeriodApply = async () => {
        if(initialPeriod.value && finalPeriod.value) {
            if(!applyingPeriod&&formData) {
                setApplyingPeriod(true);
                await formData;
                
                const periodObj = {
                    initialPeriod:initialPeriod.value,
                    finalPeriod:finalPeriod.value
                }
                
                const qfr = query(doc(db, "uploadparams/fr"));
                await updateDoc(qfr, {period:periodObj});
                
                const qen = query(doc(db, "uploadparams/en"));
                await updateDoc(qen, {period:periodObj});
    
                setApplyingPeriod(false);
            }
        } else {
            setPeriodError(language==='fr'?'Veuillez remplir tous les champs':'Please, fill all fields');
        }
    }

    useEffect(() => {
        setOrganisation(null);
        if(orgError) setOrgError(null);
    }, [orgCountry]);
    useEffect(() => {
        if(initialPeriod?.value>= finalPeriod?.value) {
            setFinalPeriod('');
        }
    }, [initialPeriod])
    useEffect(() => {
        getFormParams();
    }, []);

  return (
    <Container>
            <h1 style={{textAlign:'center'}}>{language=='fr'?'Modifier le formulaire':'Upgrade the form'}</h1>
            <div className="formElement">
                <span style={{marginLeft:10, fontSize:20}}>Pays</span>
                <div className="formSection" >
                    <div>
                        <CustomSelect
                            placeholder={language==='fr'?"Pays":'Country'}
                            size={4}
                            options={formData? formData[language].country:[]}
                            // value={country}
                            // setState={(value) => {
                            //     setCountry(value);
                            //     setOrganisation('');
                            // }}            
                        />
                        <div style={{marginTop:30}}></div>
                        <CustomInput
                            placeholder="Code a trois lettres"
                            value={addCountryCode}
                            onChange={(e) => setAddCountryCode(e.target.value.replace(/[\/:; {}\.]/gi, '').slice(0, 3).toUpperCase())}
                        />
                        <CustomInput
                            placeholder="Nom (en Français)"
                            value={addCountryNameFr}
                            onChange={(e) => setAddCountryNameFr(e.target.value.replace(/[\/:;]/gi, ''))}
                        />
                        <CustomInput
                            placeholder="Nom (en Anglais)"
                            value={addCountryNameEn}
                            onChange={(e) => setAddCountryNameEn(e.target.value.replace(/[\/:;\.]/gi, ''))}
                        />
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:20, gap:30}}>
                            <div onClick={onAddCountry} style={{borderRadius:10}} className="btn btn-primary">
                                {language==='fr'?'Ajouter le pays':'Add the country'}
                            </div>
                            {addingCountry?<div style={{color:'#1C7D2D'}} class="spinner-border"/>:null}
                        </div>
                    </div>
                    <div className="viewer">
                        <h4 style={{paddingTop:15, marginLeft:30, alignSelf:'flex-start'}}>{language=='fr'?'Pays':'Country'}</h4>
                        {formData?
                            formData[language].country.map (item => {
                            return (
                                <div className="itemContainer">
                                    <span>
                                        {item.label}
                                    </span>
                                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                                        <span>
                                            {item.value}
                                        </span>
                                        <div onClick={() => onRemoveCountry(item.value)} className="deleteItem">
                                            <IoTrashBin style={{marginTop:2}} size={18}/>
                                        </div>
                                    </div>
                                </div>
                            )
                        }):null}
                    </div>
            </div>
        </div>
        <div className="formElement">
            <span style={{marginLeft:10, fontSize:20}} >Organisation</span>
                <div className="formSection">
                    <div>
                        <CustomSelect
                            placeholder={language==='fr'?"Pays":'Country'}
                            size={4}
                            options={formData ?formData[language].country:[]}
                            value={orgCountry}
                            setState={(value) => {
                                setOrgCountry(value);
                                setOrganisation(null);
                            }}
                        />

                        {/* {
                            orgCountry?
                            <CustomSelect
                                placeholder="Organisation"
                                size={4}
                                options={formData[language].organisation[orgCountry.value]}
                                noOptionsMessage={orgCountry? 'Aucune organisation':'Choisissez un pays'}
                                value={organisation}
                                setState={setOrganisation}
                            />
                            :
                            <CustomSelect
                                type="organisation"
                                placeholder="Organisation"
                                size={4}
                                noOptionsMessage={() =>"Veuillez selectionner un pays"}
                                // options={dBDocs[language].organisation.CMR}
                            />
                        } */}
                        <div style={{marginTop:30}}></div>
                        <CustomInput
                            placeholder="Sigle de l'organisation"
                            value={addOrgSigle}
                            onChange={(e) => setAddOrgSigle(e.target.value.replace(/[\/:;{ }\.]/gi, '').toUpperCase())}
                        />
                        <CustomInput
                            placeholder="Nom de l'organisation (en Français)"
                            value={addOrgFr}
                            onChange={(e) => setAddOrgFr(e.target.value.replace(/[\/:;{}\.]/gi, ''))}
                        />
                        <CustomInput
                            placeholder="Nom (en Anglais)"
                            value={addOrgEn}
                            onChange={(e) => setAddOrgEn(e.target.value.replace(/[\/:;{}\.]/gi, ''))}
                        />

                        {orgError?<span style={{marginLeft:10, color:'red'}}>{orgError}</span>:null}
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:20, gap:30}}>
                            <div onClick={onAddOrganisation} style={{borderRadius:10}} className="btn btn-primary">
                                {language==='fr'?"Ajouter l'organisation":'Add the organisation'}
                            </div>
                            {addingOrg?<div style={{color:'#1C7D2D'}} class="spinner-border"/>:null}
                        </div>
                    </div>
                    <div className="viewer">
                        <h4 style={{paddingTop:15, marginLeft:30, alignSelf:'flex-start'}}>Organisations</h4>
                        
                        {formData && formData[language].organisation[orgCountry?.value]?
                            (formData[language].organisation[orgCountry?.value]).map (item => {
                                return (
                                    <div className="itemContainer">
                                        <span>
                                            {item.label}
                                        </span>
                                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                                            <span>
                                                {item.value}
                                            </span>
                                            <div onClick={() => onRemoveOrganisation(item.value)} className="deleteItem">
                                                <IoTrashBin style={{marginTop:2}} size={18}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        :
                        <span style={{alignSelf:'center', fontSize:17, color:'#444'}}>
                            {language==='fr'? orgCountry?'Aucune organisation':'Choisissez un pays':orgCountry?'No organisation':'Choose a country'}
                        </span>
                    }
                    </div>
            </div>
        </div>
        <div className="formElement">
            <p style={{marginLeft:10, fontSize:20}} >Période</p>
                <span style={{marginLeft:10, marginTop:10}}>Année initiale</span>
                <CustomSelect
                    setState={(value) => setInitialPeriod(value)}
                    value={initialPeriod}
                    placeholder='...'
                    options={periodOptions}
                />
                <span style={{marginLeft:10}}>Année finale</span>
                <CustomSelect
                    setState={(value) => setFinalPeriod(value)}
                    value={finalPeriod}
                    options={finalPeriodOptions}
                    placeholder='...'
                />
                {periodError?<span style={{marginLeft:10, color:'red'}}>{periodError}</span>:null}
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:20, gap:30}}>
                    <div onClick={onPeriodApply} style={{borderRadius:10}} className="btn btn-primary">
                        {language==='fr'?"Appliquer la période":'Apply the period'}
                    </div>
                    {applyingPeriod?<div style={{color:'#1C7D2D'}} class="spinner-border"/>:null}
                </div>
        </div>
    </Container>
  )
}

export default FormUpgrade;

const Container = styled.div`
    padding-left:40px;
    padding-right:40px;
    .formElement {
        border-bottom: 1px solid #888;
        margin-top:30px;
        padding-bottom:25px;
        margin-bottom:15px;

        .formSection {
            display:flex;
            margin-top:10px;
            flex-direction:row;
            flex:1;
            justify-content:space-between;
            .viewer {
                max-height:300px;
                overflow-y:scroll;
                max-width:500px;
                background-color:rgba(28,125,45, 0.3);
                border-radius:10px;
                display:flex;
                flex:1;
                flex-direction:column;
                padding-left:20px;
                padding-right:20px;
            }
        }
    }
    .itemContainer {
        display:flex;
        background-color:white;
        margin-top:8px;
        margin-bottom:8px;
        margin-left:10px;
        margin-right:10px;
        justify-content:space-between;
        padding-left:10px;
        padding-right:10px;
        padding-top:3px;
        padding-bottom:3px;
        border-radius:5px;
        
        .deleteItem {
            display:flex;
            margin-left:15px;
            height:24px;
            width:24px;
            border-radius:50%;
            align-items:center;
            justify-content:center;
            transition: 0.1s ease-in-out;
            &:hover{
            background-color:rgba(0, 0, 0, 0.2); 
            }
        }
    }

    @media screen and (min-width: 280px) and (max-width: 1080px) {
        padding-left:15px;
        padding-right:15px;
        .formElement {
            .formSection {
                flex-direction:column;
                gap:30px;
            }
        }
    }

    @media screen and (max-width: 280px) {
        .formElement {
            display:none;
        }
    }
`