import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { UserContext } from "../context/UserContext";

const PageNotFound = () => {
    const {language} = useContext(UserContext);
    return (
        <Container>
            <h1>
                {language =='fr'? 'page non trouvée' :'page not found !'}
            </h1>
            <span>
                {language =='fr'? "La page que vous recherchez est en panne ou n'existe pas"
                    :
                "The page you are looking for is broken or does'nt exist"}
            </span>
            <Link to="/" style={{marginTop:10, backgroundColor:'#0169b2'}} className="btn btn-primary shadow-none" > {language =='fr'?"retourner a l'écran d'accueil":'back to home screen'}</Link>
        </Container>
    )
}

export default PageNotFound;

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction:column;
    height:70vh;
    align-items: center;
    justify-content: center;
    background-color:rgba(28,125,45, 0.5);
    margin-top:20px;
    margin-bottom:30px;
    border-radius:10px;
    span {
        text-align:center;
        font-size:20px;
        margin:10px;
    }
`